import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {FaXTwitter, FaDiscord} from 'react-icons/fa6';
import {isValidOfferId} from "../utils/regexUtils";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

function Landingpage() {
    const faqRefs = useRef([]);
    const navigate = useNavigate();

    const [offerId, setOfferId] = useState('');
    const [offerIdValid, setOfferIdValid] = useState(false);
    const [activeFaq, setActiveFaq] = useState(null);
    const [linktreeIconSrc, setLinktreeIconSrc] = useState('/icon/linktree.png');

    const DISCORD_LINK = "https://discord.gg/s7g8nv2Gs6";

    const FAQS = [
        {
            'question': "What is Deal Guardian?",
            'answer': "<p>Deal Guardian is your trusted platform for securely trading NFTs for cryptocurrency with strangers.</p>" +
                "<p>Start by finding a trade partner, perhaps in an \"OTC\" channel on your favourite NFT project's Discord. Once you've agreed on the details, the NFT holder creates an offer in Deal Guardian and shares the Offer ID with the buyer. The buyer can then review and confirm the trade details before proceeding.</p>" +
                "<p>Rest assured, your NFTs stay safe in your wallet until the buyer accepts the deal. Deal Guardian never takes custody of your assets. We simply ensure that either both the NFTs and the crypto are exchanged, or nothing happens — no half-measures, no risks.</p>"
        }, {
            'question': "How does Deal Guardian keep my trades safe?",
            'answer': "<p>Deal Guardian offers a robust suite of security features to protect your trades:<ul>" +
                "<li><strong>Custody of Your NFT</strong>: Your NFTs stay in your wallet until the trade is finalised. While we request an approval for the trade, the actual transfer only happens when the offer is accepted.</li>" +
                "<li><strong>Atomic Transfers</strong>: Our smart contracts ensure that the NFT transfer and crypto payment happen simultaneously. If one part of the transaction fails, the whole deal is cancelled, safeguarding your assets.</li>" +
                `<li><strong>Open Source Contracts</strong>: Our contracts are open source and verified, meaning anyone can inspect them to verify their security and integrity (the contract addresses for each chain can be found in our <a href=${DISCORD_LINK} target=\"_blank\" rel=\"noopener noreferrer\">Discord</a> or on our <a href=\"https://linktr.ee/dealguardian\" target=\"_blank\" rel=\"noopener noreferrer\">Linktree</a>). Trust, but verify!</li>` +
                "<li><strong>Active Revocation Prompting</strong>: We only ask for the necessary approvals for each trade. Plus, our system actively prompts you to revoke permissions once a trade is completed or withdrawn, keeping your wallet secure.</li>" +
                "<li><strong>Token Risk Warnings</strong>: We integrate with Honeypot.is to warn you of risky ERC-20 tokens. This ensures you're aware of potential transaction taxes or other risks before proceeding with a trade.</li>" +
                "</ul></p>"
        }, {
            'question': "Why is it so much cheaper than a marketplace? What's the catch?",
            'answer': "<p>Deal Guardian focuses on what matters most: safe trades. Unlike full-featured NFT marketplaces, we don't include extras like browsing, filtering offers, or managing collections.</p>" +
                "<p>Our lean approach means lower costs, which we pass on to you. But rest assured, we've spared no effort on the security front — your safety is our top priority!</p>"
        }, {
            'question': "What if my NFT distributes rewards while it's listed?",
            'answer': "<p>You'll continue to receive all rewards and airdrops as usual since your NFT remains securely in your wallet until the offer is accepted. You can claim these rewards through the respective dApp, just as you normally would.</p>"
        }, {
            'question': "Can I withdraw my offer?",
            'answer': "<p>Absolutely! If you change your mind or the other party goes silent, you can easily withdraw your offer anytime before it's accepted.</p>" +
                "<p>On your first withdrawal, you'll receive a full refund of the listing fee in USDC<span style='font-style: italic'>*</span>. For any subsequent withdrawals, you'll still get 50% of the fee refunded. Your satisfaction and flexibility are our priorities.</p>" +
                "<p class='small-hint mb-0'>* On Ethereum refunds will be issued in ETH so you don't have to pay extra gas fees for converting ETH to USDC during the offer creation.</p>"
        }, {
            'question': "Do I need to browse offers?",
            'answer': "<p>No, Deal Guardian is designed for direct, OTC (Over The Counter) trades. You'll connect with your trade partner through other channels, such as an \"OTC\" Discord channel, and settle the deal details there. Our platform then ensures a smooth, secure transaction, guaranteeing that neither party can back out without fulfilling their end of the deal.</p>"
        }, {
            'question': "What technologies are supported?",
            'answer': "<p>Currently, Deal Guardian supports the Ethereum and Base blockchains, with more blockchain integrations coming soon. We support ERC-20 tokens and ERC-721 NFTs, but ERC-1155 is not yet supported.</p>"
        }
    ];

    useEffect(() => {
        if (activeFaq !== null && faqRefs.current[activeFaq]) {
            faqRefs.current[activeFaq].style.maxHeight = `${faqRefs.current[activeFaq].scrollHeight + 40}px`;
            faqRefs.current[activeFaq].style.padding = `1rem 1.25rem`;

            // The scrollHeight can be slightly off at this time if adding the padding creates new line breaks (as the
            // padding is still animating in at this point).
            // Because of this we let the animation finish (0.3s in CSS) and then re-set the height (this causes a very
            // slightly jumpy UI whenever this changes the value / has an effect, but without it the text is cut off).
            setTimeout(() => {
                faqRefs.current[activeFaq].style.maxHeight = `${faqRefs.current[activeFaq].scrollHeight + 40}px`;
            }, 300);
        }

        return () => {
            if (activeFaq !== null && faqRefs.current[activeFaq]) {
                faqRefs.current[activeFaq].style.maxHeight = '0';
                faqRefs.current[activeFaq].style.padding = "0";
            }
        };
    }, [activeFaq]);

    const handleOfferIdChange = (event) => {
        setOfferId(event.target.value);
        setOfferIdValid(isValidOfferId(event.target.value));
    };

    const handleViewOfferClick = () => {
        navigate('/offer/' + offerId);
    };

    const toggleFaq = (index) => {
        setActiveFaq(activeFaq === index ? null : index);
    };

    const handleMouseEnterLinktreeIcon = () => {
        setLinktreeIconSrc('/icon/linktree_hover.png');
    };

    const handleMouseLeaveLinktreeIcon = () => {
        setLinktreeIconSrc('/icon/linktree.png');
    };

    return (
        <div>
            <main>
                <section className="hero-section text-center">
                    <div className="container">
                        <h1>Your Safe Solution for OTC NFT Deals</h1>
                        {/*<h1>The Trusted Way to Trade NFTs OTC</h1>*/}
                        <p className="hero-text">For just $2.99, safeguard your trades and never worry about losing your
                            assets to untrustworthy partners.</p>
                        <div className="cta-buttons">
                            <Link to="/makeOffer" className="btn btn-primary btn-lg">Sell NFTs safely</Link>
                            <p className="mt-5 mb-0">Already got an Offer ID?</p>
                            <div className="input-group justify-content-center">
                                <input className="form-control landingpage-offerid-input"
                                       placeholder="Enter Offer ID"
                                       value={offerId}
                                       onChange={handleOfferIdChange}/>
                                <button onClick={handleViewOfferClick} disabled={!offerIdValid}
                                        className="btn btn-secondary">View Offer
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="features-section container text-center">
                    <h2>Why Choose Deal Guardian?</h2>
                    <div className="row">
                        <div className="col-md-4 px-4 py-2">
                            <h3>Trust & Security</h3>
                            <p>Don’t let your valuable NFTs fall into the wrong hands. With Deal Guardian, every trade
                                is secure, ensuring you get what you agreed upon.</p>
                            <img src="/illustration/padlock.svg" alt="Padlock" className="landingpage-illustration mb-4"/>
                        </div>
                        <div className="col-md-4 px-4 py-2">
                            <h3>Affordability</h3>
                            <p>For less than a cup of coffee, protect your assets and sleep easy knowing your trades are
                                safe.</p>
                            <img src="/illustration/bitcoin.svg" alt="Bitcoin" className="landingpage-illustration mb-4"/>
                        </div>
                        <div className="col-md-4 px-4 py-2">
                            <h3>Peace of Mind</h3>
                            <p>Say goodbye to the anxiety of trusting strangers. We make sure every trade is fair and
                                transparent.</p>
                            <img src="/illustration/peace-of-mind.svg" alt="Peace of mind"
                                 className="landingpage-illustration mb-4"/>
                        </div>
                    </div>
                </section>

                {/* TODO: insert section "Use Cases" with a carousel featuring the following use cases:
                 - Trade an NFT for crypto
                 - Trade an NFT plus that projects token (e.g. BAYC & Ape) together in one trade if you need both the NFT + at least n tokens to unlock a feature
                 - Trade crypto for crypto if one of the tokens has a high buy/sell tax but no transfer tax
                 */}

                {/* TODO: insert section "Supported blockchains" without a title but rather a horizontal bar with the logos */}

                <section className="comparison-section container text-center">
                    <h2>Real Savings, Real Security</h2>
                    <p>Compare our fixed fee of $2.99 USD with traditional NFT marketplaces which charge 2.5% of the sale
                        price:</p>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Example Trade</th>
                            <th>Deal Guardian Fee</th>
                            <th>Traditional Marketplace Fee</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Trading a cheap NFT for $150</td>
                            <td className="table-success">Fixed $2.99</td>
                            <td>$3.75</td>
                        </tr>
                        <tr>
                            <td>Exchanging two NFTs for a total of $1,000</td>
                            <td className="table-success">Still just $2.99</td>
                            <td>$25.00</td>
                        </tr>
                        <tr>
                            <td>Selling your favourite NFT for $8,000</td>
                            <td className="table-success">A mere $2.99</td>
                            <td>$200.00</td>
                        </tr>
                        </tbody>
                    </table>
                </section>

                {/* TODO: insert section "Reviews" (and include funny review like "I don't understand what it does but it sounds really advanced." - Mom (thanks Mom)) */}

                <section className="how-it-works-section container text-center">
                    <h2>How It Works</h2>
                    <div className="row">
                        <div className="col-md-4 px-4 py-2">
                            <h3>Step 1</h3>
                            <p className="mb-0">Create a secure offer with your NFTs and desired crypto. When submitting the offer you'll pay a $2.99 fee, paid directly in the blockchains native coin.</p>
                            <img src="/illustration/make-offer.svg" alt="Hand with price tag"
                                 className="landingpage-illustration mb-4"/>
                        </div>
                        <div className="col-md-4 px-4 py-2">
                            <h3>Step 2</h3>
                            <p className="mb-0">Share your Offer ID with the other party. The buyer can now check both sides of the deal and verify it's what you agreed on.</p>
                            <img src="/illustration/share.svg" alt="Share" className="landingpage-illustration mb-4"/>
                        </div>
                        <div className="col-md-4 px-4 py-2">
                            <h3>Step 3</h3>
                            <p className="mb-0">The buyer now accepts the offer with confidence, knowing the assets are protected.</p>
                            <img src="/illustration/handshake.svg" alt="Handshake" className="landingpage-illustration mb-4"/>
                        </div>
                    </div>
                </section>

                <section className="faq-section container">
                    <h2 className="text-center">Frequently Asked Questions</h2>
                    <div className="accordion" id="faqAccordion">
                        {FAQS.map((qa, index) => (
                            <div className="card" key={index} onClick={() => toggleFaq(index)}>
                                <div className="card-header" id={`faq${index}`}>
                                    <h5 className="mb-0 d-flex justify-content-between">
                                        <button
                                            className="btn"
                                            type="button"
                                            aria-expanded={activeFaq === index}>
                                            {qa.question}
                                        </button>
                                        <span className={`caret ${activeFaq === index ? 'rotate' : ''}`}>
                                            ▼
                                        </span>
                                    </h5>
                                </div>
                                <div
                                    id={`collapse${index}`}
                                    className={`collapse show`}
                                    aria-labelledby={`faq${index}`}
                                    data-parent="#faqAccordion">
                                    <div
                                        className="card-body"
                                        ref={(el) => (faqRefs.current[index] = el)}
                                        dangerouslySetInnerHTML={{ __html: qa.answer }} />
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </main>

            <footer className="bg-light text-center">
                <div className="container">
                    <p className="mb-1">&copy; 2024 Deal Guardian. All rights reserved.</p>
                    <p className="small">Developed by NakamotoNomad</p>
                    <div className="social-icons">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    <span>X (Twitter)</span>
                                </Tooltip>
                            }>
                                <a href="https://x.com/DealGuardianApp" target="_blank" rel="noopener noreferrer">
                                    <FaXTwitter />
                                </a>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    <span>Discord</span>
                                </Tooltip>
                            }>
                                <a href={DISCORD_LINK} target="_blank" rel="noopener noreferrer">
                                    <FaDiscord />
                                </a>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    <span>Linktree</span>
                                </Tooltip>
                            }>
                                <a href="https://linktr.ee/dealguardian" target="_blank" rel="noopener noreferrer" onMouseEnter={handleMouseEnterLinktreeIcon} onMouseLeave={handleMouseLeaveLinktreeIcon}>
                                    <img src={linktreeIconSrc} height="16px" alt="Linktree Icon"/>
                                </a>
                        </OverlayTrigger>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Landingpage;
