import React, { createContext, useContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { useWeb3Modal, useDisconnect, useSwitchNetwork, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';
import {DEAL_GUARDIAN_ABI} from '../constants';
import {getChainByChainId} from "../utils/chainUtils";

const BlockchainContext = createContext();

export const useBlockchain = () => useContext(BlockchainContext);

export const BlockchainProvider = ({ children }) => {
    const { open } = useWeb3Modal();
    const { disconnect } = useDisconnect();
    const { switchNetwork } = useSwitchNetwork();
    const { walletProvider } = useWeb3ModalProvider();
    const { chainId, isConnected } = useWeb3ModalAccount();

    const [provider, setProvider] = useState(null);
    const [contract, setContract] = useState(null);
    const [signer, setSigner] = useState(null);
    const [chainInfos, setChainInfos] = useState(null);

    useEffect(() => {
        const setStateVariables = async () => {
            if (isConnected) {
                const provider = new ethers.BrowserProvider(walletProvider);
                const signer = await provider.getSigner();
                const network = await provider.getNetwork();
                if (!network || !network.chainId) {
                    console.log(`Can't read selected chain (network) in wallet`);
                    return;
                }

                const chainInfos = getChainByChainId(Number(network.chainId));
                if (!chainInfos) {
                    console.log(`Unsupported chainId: ${network.chainId}`);
                    return;
                }
                const contract = new ethers.Contract(chainInfos.contracts.DealGuardian, DEAL_GUARDIAN_ABI, signer);

                setProvider(provider);
                setSigner(signer);
                setContract(contract);
                setChainInfos(chainInfos);
            } else {
                setProvider(null);
                setSigner(null);
                setContract(null);
                setChainInfos(null);
            }
        }

        setStateVariables();
    }, [walletProvider, isConnected, chainId]);

    const connectWallet = async () => {
        try {
            await open();
        } catch (e) {
            console.error('Error connecting to wallet:', e);
        }
    };

    const disconnectWallet = async () => {
        try {
            await disconnect();
        } catch (e) {
            console.error('Error disconnecting wallet:', e);
        }
    };

    const switchToChain = async (newChainId) => {
        try {
            await switchNetwork(newChainId);
        } catch (e) {
            console.error('Error switching networks:', e);
        }
    };

    return (
        <BlockchainContext.Provider value={{ provider, contract, signer, chainInfos, connectWallet, disconnectWallet, switchToChain }}>
            {children}
        </BlockchainContext.Provider>
    );
};
