import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {Tab, Tabs} from "react-bootstrap";
import {useBlockchain} from "../contexts/BlockchainContext";
import ConnectedWalletRequired from "./ConnectedWalletRequired";
import OfferTable from "./OfferTable";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

function MyOffers() {
    const location = useLocation();
    const navigate = useNavigate();
    const {contract, provider} = useBlockchain();
    const { address } = useWeb3ModalAccount();

    const [offersReceived, setOffersReceived] = useState({});
    const [offersMade, setOffersMade] = useState({});

    const searchParams = new URLSearchParams(location.search);
    const defaultTab = searchParams.get('tab') || 'offersMade';

    useEffect(() => {
        const fetchOffers = async () => {
            if (!address || !contract || !provider) {
                setOffersReceived({});
                setOffersMade({});
                return;
            }

            const offersReceived = {};
            const offersMade = {};

            try {
                const offerIds = await contract.getActiveOfferIds();

                for (let offerId of offerIds) {
                    let {
                        makerAddress,
                        makerAssets,
                        takerAssets,
                        reservedForAddress,
                        offerMadeTimestamp
                    } = await contract.getOffer(offerId);
                    let offer = {
                        makerAddress: makerAddress,
                        makerAssets: makerAssets,
                        takerAssets: takerAssets,
                        reservedForAddress: reservedForAddress,
                        offerMadeTimestamp: offerMadeTimestamp
                    };
                    if (reservedForAddress === address) {
                        offersReceived[offerId] = offer;
                    }
                    if (makerAddress === address) {
                        offersMade[offerId] = offer;
                    }
                }

                setOffersReceived(offersReceived);
                setOffersMade(offersMade);
            } catch (error) {
                console.error('Error fetching offers:', error);
                setOffersReceived({});
                setOffersMade({});
            }
        };

        fetchOffers();
    }, [address, contract, provider]);

    const handleTabSelect = (key) => {
        navigate(`?tab=${key}`);
    };

    if (!address) {
        return <ConnectedWalletRequired/>;
    }

    return (
        <div className="container mt-4">
            <h1 className="text-center mb-4">My Offers</h1>
            <Tabs
                activeKey={defaultTab}
                onSelect={handleTabSelect}
                id="incoming-outgoing-tab">
                <Tab eventKey="offersMade" title="Offers made">
                    <OfferTable offers={offersMade} showMaker={false} showReservedFor={true} />
                </Tab>
                <Tab eventKey="offersReceived" title="Offers received">
                    <OfferTable offers={offersReceived} showMaker={true} showReservedFor={false} />
                </Tab>
            </Tabs>
        </div>
    );
}

export default MyOffers;
