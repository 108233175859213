export const formatStringTwoDecimals = (str) => {
    if (!str || typeof str !== 'string')
        return str;

    let parts = str.split('.');

    // Format the integer part with thousands delimiters
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Ensure two decimals
    if (parts.length > 1) {
        if (parts[1].length > 2) {
            if (parts[0] !== "0" || parts[1].slice(0, 2) !== "00") {
                parts[1] = parts[1].slice(0, 2);
            }
        } else if (parts[1].length < 2) {
            parts[1] = parts[1] + '0';
        }
    } else {
        parts.push('00'); // If no decimals, add ".00"
    }

    return parts.join('.');
};

export const getAddressDisplayName = (address) => {
    if (!address) {
        return "N/A";
    }
    return address.slice(0, 7) + "..." + address.slice(-5);
}