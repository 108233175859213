import {CHAINS} from '../constants';

export const getChainByName = (name) => {
    if (!name) return null;
    return CHAINS.find(chain => chain.name.toUpperCase() === name.toUpperCase());
};

export const getChainByChainId = (chainId) => {
    if (!chainId) return null;
    if (typeof chainId === 'string') {
        chainId = Number(chainId);
    }
    return CHAINS.find(chain => chain.chainId === chainId);
};

export const generateDynamicUrl = (urlTemplate, params) => {
    return urlTemplate.replace(/\{(\w+)\}/g, (_, key) => params[key] || '');
}
