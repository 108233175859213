import React from "react";
import {TransactionStatus} from "../enums";
import {FaCheck, FaTriangleExclamation} from "react-icons/fa6";

export const getTransactionStatusSymbol = (status) => {
    switch (status) {
        case TransactionStatus.PENDING:
            return '';
        case TransactionStatus.SUCCESS:
            return <FaCheck className="transaction-icon-success" />;
        case TransactionStatus.IN_PROGRESS:
            return <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>;
        default:
            return <FaTriangleExclamation className="transaction-icon-error" />;
    }
};