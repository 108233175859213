const HONEYPOT_IS_URL = "https://api.honeypot.is/v2/IsHoneypot"

export const fetchHoneypotIsData = async (address, chainInfos) => {
    if (!chainInfos.abilities.honeypot_is) {
        console.log(`Can't load honeypot data for address ${address} because chain ID ${chainInfos.chainId} isn't supported by HoneypotIs`);
        return {};
    }

    const url = new URL(HONEYPOT_IS_URL);

    url.searchParams.append('address', address);
    url.searchParams.append('chainId', chainInfos.chainId);

    const response = await fetch(url);
    return await response.json();
}

export const createLinkToHoneypotIsWebsite = (address, chainInfos) => {
    switch (chainInfos.chainId) {
        case 1: // Ethereum
            return `https://honeypot.is/ethereum?address=${address}`;
        case 8453: // Base
            return `https://honeypot.is/base?address=${address}`;
        default:
            console.error("Can't generate link to Honeypot.Is because it doesn't support chainId", chainInfos.chainId);
            return "https://honeypot.is/";
    }
}
