import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import {BlockchainProvider} from 'contexts/BlockchainContext';
import NotFoundPage from "components/NotFoundPage";
import Header from 'components/Header';
import MakeOffer from "components/MakeOffer";
import OfferMade from "components/OfferMade";
import ViewOffer from "components/ViewOffer";
import BrowseOffers from "components/BrowseOffers";
import OfferAccepted from "components/OfferAccepted";
import MyOffers from "components/MyOffers";
import Admin from "components/Admin";
import Landingpage from "components/Landingpage";
import {createWeb3Modal, defaultConfig} from "@web3modal/ethers/react";
import {CHAINS, HOST_LOCALHOST, WALLET_CONNECT_PROJECT_ID} from "./constants";

let { host } = window.location;
const showTestnets = host === HOST_LOCALHOST;

const chains = CHAINS
    .filter(chain => (showTestnets || chain.testnet === false))
    .map(chain => ({
    chainId: chain.chainId,
    name: chain.name,
    currency: chain.nativeCurrency.symbol,
    explorerUrl: chain.blockExplorerUrls ? chain.blockExplorerUrls[0] : "",
    rpcUrl: chain.rpcUrls ? chain.rpcUrls[0] : "",
}));

const metadata = {
    name: 'Deal Guardian',
    description: 'Your Safe Solution for OTC NFT Deals',
    url: 'https://dealguardian.io',
    icons: ['https://dealguardian.io/logo_light_blue.svg']
}

const ethersConfig = defaultConfig({
    metadata,
    auth: {
        email: false,
        socials: false,
        showWallets: true,
        walletFeatures: false,
    }
})

createWeb3Modal({
    ethersConfig,
    chains: chains,
    projectId: WALLET_CONNECT_PROJECT_ID,
})

function App() {
    return (
        <BlockchainProvider>
            <Router>
                <Header showTestnets={showTestnets}/>
                <Routes>
                    {/* When adding a component which only works with connected wallet: add to robot.txt too */}
                    <Route path="/" element={<Landingpage />}/>
                    <Route path="/makeOffer" element={<MakeOffer />}/>
                    <Route path="/offerMade/:offerId" element={<OfferMade />}/>
                    <Route path="/offer/:offerId" element={<ViewOffer />}/>
                    <Route path="/offerAccepted/:offerId" element={<OfferAccepted />}/>
                    <Route path="/myOffers" element={<MyOffers />}/>
                    <Route path="/browseOffers" element={<BrowseOffers />}/>
                    <Route path="/admin" element={<Admin />}/>
                    <Route path="*" element={<NotFoundPage />}/>
                </Routes>
            </Router>
        </BlockchainProvider>
    );
}

export default App;
