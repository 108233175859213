import React, {useEffect, useState} from 'react';
import NftExternalLinks from "./NftExternalLinks";
import {useBlockchain} from "../contexts/BlockchainContext";
import {enrichNfts} from "../utils/nftUtils";

const NftCards = ({nftsRaw, enrichedNftsParent, selectedNfts, handleSelectionClick}) => {
    const {chainInfos} = useBlockchain();

    const [enrichedNfts, setEnrichedNfts] = useState([]);

    useEffect(() => {
        const enrichAndSetNfts = async () => {
            if (enrichedNftsParent) {
                setEnrichedNfts(enrichedNftsParent);
                return;
            }

            if (!chainInfos || !nftsRaw) {
                setEnrichedNfts([]);
                return;
            }

            let enrichedNftsNew = await enrichNfts(chainInfos.chainId, nftsRaw);
            setEnrichedNfts(enrichedNftsNew);
        };

        enrichAndSetNfts();
    }, [nftsRaw, enrichedNftsParent, chainInfos]);

    return (
        <div className="container mt-4">
            <div className="row">
                {enrichedNfts.map(nft => (
                    <div key={nft['collectionAddress'] + nft['tokenId'].toString()}
                         className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                {nft['thumbnailVideo'] ? (
                                    <video autoPlay muted loop className="card-img-top img-fluid"
                                           controls={false}
                                           style={{objectFit: 'cover', height: '200px'}}>
                                        <source src={nft['thumbnail']} type="video/mp4"/>
                                        Your browser does not support the video tag.
                                    </video>
                                ) : (
                                    <img src={nft['thumbnail']} className="card-img-top img-fluid"
                                         alt="NFT"
                                         style={{objectFit: 'cover', height: '200px'}}/>
                                )}
                                <h5 className="card-title">{nft['name']}</h5>
                                <p className="card-text">ID: {nft['tokenId'].toString()}</p>
                                <div className="d-flex justify-content-between align-items-center">
                                    {handleSelectionClick ? (
                                        <button
                                            onClick={() => handleSelectionClick(nft)}
                                            className={`btn ${selectedNfts.some(selectedNft => selectedNft.collectionAddress === nft.collectionAddress && selectedNft.tokenId === nft.tokenId) ? 'btn-danger' : 'btn-success'}`}>
                                            {selectedNfts.some(selectedNft => selectedNft.collectionAddress === nft.collectionAddress && selectedNft.tokenId === nft.tokenId) ? 'Keep' : 'Sell'}
                                        </button>
                                    ) : (<span></span>)} {/* empty span to align the external links on the right hand side */}
                                    <NftExternalLinks chainInfos={chainInfos} contractAddress={nft['collectionAddress']}
                                                      tokenId={nft['tokenId'].toString()}/>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NftCards;
