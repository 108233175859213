import React from 'react';
import {Link, useParams} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QRCodeSVG from 'qrcode.react';
import {getAbsoluteUrl} from "../utils/urlUtils";

function OfferMade() {
    const { offerId } = useParams();

    const getOfferUrl = () => {
        return getAbsoluteUrl(`/offer/${offerId}`);
    }

    const copyOfferUrlToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(getOfferUrl());
            toast.success('Link copied!', { autoClose: 2000 });
        } catch (err) {
            toast.error('Failed to copy!', { autoClose: 2000 });
        }
    };

    return (
        <div className="container mt-4">
            <h1 className="text-center mb-4">Offer Made!</h1>
            <div className="d-flex justify-content-center">
                <p className="text-center mb-3">You can send the potential buyer this Offer ID to complete the deal:</p>
            </div>
            <p className="text-center mb-4">
                <strong><Link to={`/offer/${offerId}`} className="text-lg">{offerId}</Link></strong>
                <button onClick={copyOfferUrlToClipboard} className="btn btn-primary btn-lg ms-2">Copy Link</button>
            </p>
            <div className="d-flex justify-content-center">
                <QRCodeSVG value={getOfferUrl()} size="300" bgColor="#dde7f3" imageSettings={{
                    src: "/logo_black.svg",
                    height: 60,
                    width: 60,
                    excavate: true,
                }} />
            </div>
            <ToastContainer />
        </div>
    );
}

export default OfferMade;
