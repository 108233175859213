import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FaArrowRightArrowLeft} from 'react-icons/fa6';

const OfferSummary = ({enrichedNfts, amountFormatted, amountUsdFormatted, currencyInfo, feesText, feesClassNames}) => {
    return (
        <div className="summary-parent-div">
            <div className="offer-summary">
                <div className="offer-details">
                    <div className="nfts-container">
                        {enrichedNfts.map((nft) => (
                            <OverlayTrigger
                                key={`${nft.collectionAddress}-${nft.tokenId}`}
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-${nft.collectionAddress}-${nft.tokenId}`}>
                                        <div className="nft-tooltip">
                                            <img src={nft.thumbnail} alt={nft.name} className="large-nft-image"/>
                                            <div>{nft.collectionName}</div>
                                            <div>Token ID: {nft.tokenId}</div>
                                        </div>
                                    </Tooltip>
                                }>
                                <img src={nft.thumbnail} alt={nft.name} className="nft-thumbnail"/>
                            </OverlayTrigger>
                        ))}
                    </div>
                    <div className="exchange-arrows">
                        <FaArrowRightArrowLeft/>
                    </div>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip-amount">
                                <span>≈ {amountUsdFormatted} USD</span>
                            </Tooltip>
                        }>
                        <div className="summary-amount-container">
                            <span className="summary-amount">{amountFormatted}</span>
                            <span className="summary-currency">{currencyInfo.name}</span>
                            <img className="summary-currency-logo" src={currencyInfo.image} alt={currencyInfo.name}/>
                        </div>
                    </OverlayTrigger>
                </div>
                <p className={`summary-fees ${feesClassNames}`}>{feesText}</p>
            </div>
        </div>
    );
};

export default OfferSummary;
