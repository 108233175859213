import {ERC_20_ABI} from '../constants';
import {ethers} from "ethers";

export const getCurrencyByName = (chainInfos, name) => {
    if (!chainInfos || !name) return null;
    return chainInfos.currencies.find(currency => currency.name.toUpperCase() === name.toUpperCase());
};

export const getCurrencyByAddress = (chainInfos, address) => {
    if (!chainInfos || !address) return null;
    return chainInfos.currencies.find(currency => currency.address.toLowerCase() === address.toLowerCase());
};

export const getDecimals = async (provider, tokenAddress) => {
    const tokenContract = new ethers.Contract(tokenAddress, ERC_20_ABI, provider);
    return await tokenContract.decimals();
}
