import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {getTransactionStatusSymbol} from "../utils/transactionUtils";
import {TransactionStatus} from "../enums";
import {enrichNfts} from "../utils/nftUtils";
import {useBlockchain} from "../contexts/BlockchainContext";
import {useNavigate} from "react-router-dom";
import {ethers} from "ethers";
import {ERC_20_ABI} from "../constants";
import OfferSummary from "./OfferSummary";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

const AcceptOfferModal = ({offerId, offer, modalShow, setModalShow, sellCurrencyAmountFormatted, sellCurrencyAmountUsdFormatted, sellCurrencyInfo}) => {
    const {provider, signer, contract, chainInfos} = useBlockchain();
    const { address } = useWeb3ModalAccount();
    const navigate = useNavigate();

    const [acceptTransactionStatus, setAcceptTransactionStatus] = useState(TransactionStatus.PENDING);
    const [cryptoApprovalTransactionStatus, setCryptoApprovalTransactionStatus] = useState(TransactionStatus.PENDING);
    const [enhancedNfts, setEnhancedNfts] = useState([]);

    useEffect(() => {
        const enrichAndSetNfts = async () => {
            setEnhancedNfts(await enrichNfts(chainInfos.chainId, offer.makerAssets.nfts));
        };

        enrichAndSetNfts();
    }, [offer, chainInfos]);

    useEffect(() => {
        const fetchCryptoApprovalStatus = async () => {
            if (!provider || !offer) {
                setCryptoApprovalTransactionStatus(TransactionStatus.PENDING);
                return;
            }

            const currencyContract = new ethers.Contract(offer.takerAssets.currencies[0].currencyAddress, ERC_20_ABI, provider);
            const allowance = await currencyContract.allowance(address, await contract.getAddress());

            let newStatus = TransactionStatus.PENDING;
            if (allowance >= offer.takerAssets.currencies[0].currencyAmount) {
                newStatus = TransactionStatus.SUCCESS;
            }

            setCryptoApprovalTransactionStatus(newStatus);
        };

        fetchCryptoApprovalStatus();
    }, [provider, offer]);

    const ensureApprovals = async () => {
        const tokenContract = new ethers.Contract(offer.takerAssets.currencies[0].currencyAddress, ERC_20_ABI, signer);
        const allowance = await tokenContract.allowance(address, await contract.getAddress());
        if (allowance < offer.takerAssets.currencies[0].currencyAmount) {
            try {
                setCryptoApprovalTransactionStatus(TransactionStatus.IN_PROGRESS);
                console.log(`Not enough allowance to accept offer, requesting more. Needed: ${offer.takerAssets.currencies[0].currencyAmount}, Allowance: ${allowance}`);
                let tx = await tokenContract.approve(await contract.getAddress(), offer.takerAssets.currencies[0].currencyAmount);
                let receipt = await tx.wait();
                console.log(`Receipt of allowance approval: ${JSON.stringify(receipt)}`);
                setCryptoApprovalTransactionStatus(TransactionStatus.SUCCESS);
            } catch (e) {
                console.error(`Error while ensuring enough crypto approval for purchase`, e);
                setCryptoApprovalTransactionStatus(TransactionStatus.ERROR);
                return false;
            }
        }
        return true;
    }

    const acceptOffer = async () => {
        try {
            setAcceptTransactionStatus(TransactionStatus.IN_PROGRESS);
            const tx = await contract.acceptOffer(offerId, {
                gasLimit: 500000,
            });
            await tx.wait();
            console.log(`Accepted offer ${offerId} successfully`);
            setAcceptTransactionStatus(TransactionStatus.SUCCESS);
        } catch (e) {
            console.error(`Error while accepting offer`, e);
            setAcceptTransactionStatus(TransactionStatus.ERROR);
            return false;
        }
        return true;
    }

    const handleBuyNowClick = async () => {
        console.log(`Accepting offer: ${offerId}`);

        let success = await ensureApprovals();

        if (success) {
            success = await acceptOffer();

            if (success) {
                navigate('/offerAccepted/' + offerId);
            }
        }
    };

    const hideModal = () => {
        // once the offer has been accepted you can't close the modal anymore
        if (acceptTransactionStatus !== TransactionStatus.SUCCESS) {
            setModalShow(false);
        }
    }

    const navigateToMyOffers = () => {
        navigate('/myOffers');
    }

    return (
        <Modal
            show={modalShow}
            onHide={hideModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton={acceptTransactionStatus !== TransactionStatus.SUCCESS}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Accept Offer
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <OfferSummary enrichedNfts={enhancedNfts} amountFormatted={sellCurrencyAmountFormatted} amountUsdFormatted={sellCurrencyAmountUsdFormatted} currencyInfo={sellCurrencyInfo} />
                    <div className="container mt-4">
                        <p>Please approve the following transactions in your wallet:</p>
                        <ol className="">
                            <li key="approve">
                                <span>Approve {sellCurrencyAmountFormatted} {sellCurrencyInfo.name} </span>
                                <span className="me-2">{getTransactionStatusSymbol(cryptoApprovalTransactionStatus)}</span>
                            </li>
                            <li key="accept">
                                <span>Accept the offer </span>
                                <span className="me-2">{getTransactionStatusSymbol(acceptTransactionStatus)}</span>
                            </li>
                        </ol>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {acceptTransactionStatus !== TransactionStatus.SUCCESS && (
                    <button onClick={handleBuyNowClick}
                            className="btn btn-primary btn-lg">Buy Now
                    </button>
                )}
                {acceptTransactionStatus === TransactionStatus.SUCCESS && (
                    <div>
                        <button onClick={navigateToMyOffers}
                                className={`btn btn-lg me-2 btn-primary`}>Done
                        </button>
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default AcceptOfferModal;
