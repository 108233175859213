import {ethers} from "ethers";
import {UNISWAP_V2_ROUTER_ABI} from "../constants";
import {getDecimals} from "./currencyUtils";
import {formatStringTwoDecimals} from "./formatUtils";

export const RATE_UNKNOWN = "0.00";

export const getConversionWei = async (chainInfo, provider, path, amountWei) => {
    const uniswapContract = new ethers.Contract(chainInfo.contracts.UniswapV2Router, UNISWAP_V2_ROUTER_ABI, provider);
    const amountsIn = await uniswapContract.getAmountsOut(amountWei, path);
    return BigInt(amountsIn[1]);
}

export const getConversionFormatted = async (chainInfo, provider, path, amountWei) => {
    if (!provider || !amountWei || !chainInfo || !path || path.length !== 2 || !path[0] || path[0] === ethers.ZeroAddress || !path[1] || path[1] === ethers.ZeroAddress) {
        return RATE_UNKNOWN;
    }

    try {
        let convertedWei;
        if (path[0] === path[1]) {
            convertedWei = amountWei;
        } else {
            convertedWei = await getConversionWei(chainInfo, provider, path, amountWei);
        }
        let convertedFormattedUnlimitedDecimals = ethers.formatUnits(convertedWei, await getDecimals(provider, path[1]));
        return formatStringTwoDecimals(convertedFormattedUnlimitedDecimals);
    } catch (error) {
        console.error(`Error fetching conversion rate:`, error);
        return RATE_UNKNOWN;
    }
};
