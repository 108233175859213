import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {FaCopy, FaQrcode} from 'react-icons/fa6';
import {toast, ToastContainer} from "react-toastify";
import QRCodeSVG from "qrcode.react";
import {ethers} from "ethers";
import {useBlockchain} from "../contexts/BlockchainContext";
import {ERC_20_ABI} from "../constants";
import NftCards from "./NftCards";
import {getCurrencyByAddress, getCurrencyByName} from "../utils/currencyUtils";
import {Alert, OverlayTrigger, Tooltip} from "react-bootstrap";
import ConnectedWalletRequired from "./ConnectedWalletRequired";
import AcceptOfferModal from "./AcceptOfferModal";
import WithdrawOfferModal from "./WithdrawOfferModal";
import {getConversionFormatted, RATE_UNKNOWN} from "../utils/conversionUtils";
import {formatStringTwoDecimals} from "../utils/formatUtils";
import {createLinkToHoneypotIsWebsite, fetchHoneypotIsData} from "../utils/securityUtils";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

function ViewOffer() {
    const {offerId} = useParams();
    const {contract, provider, signer, chainInfos} = useBlockchain();
    const { address } = useWeb3ModalAccount();

    const [offer, setOffer] = useState();
    const [amountUsdFormatted, setAmountUsdFormatted] = useState(RATE_UNKNOWN);
    const [acceptOfferModalShow, setAcceptOfferModalShow] = useState(false);
    const [withdrawOfferModalShow, setWithdrawOfferModalShow] = useState(false);
    const [currencyInfo, setCurrencyInfo] = useState({});
    const [honeypotIsResult, setHoneypotIsResult] = useState({});

    useEffect(() => {
        const fetchOffer = async () => {
            if (!contract || !address || !signer) {
                setOffer(null);
                return;
            }

            try {
                let {
                    makerAddress,
                    makerAssets,
                    takerAssets,
                    reservedForAddress,
                    offerMadeTimestamp
                } = await contract.getOffer(offerId);
                let offer = {
                    offerId: offerId,
                    makerAddress: makerAddress,
                    makerAssets: makerAssets,
                    takerAssets: takerAssets,
                    reservedForAddress: reservedForAddress,
                    offerMadeTimestamp: offerMadeTimestamp
                };
                if (makerAddress === ethers.ZeroAddress) {
                    setOffer(null);
                    console.log(`Offer doesn't seem to exist as it's returning default values`);
                } else {
                    setOffer(offer);
                }
            } catch (error) {
                console.error(`Error fetching offer with ID ${offerId}:`, error);
                setOffer(null);
            }
        };

        fetchOffer();
    }, [contract, address, signer]);

    useEffect(() => {
        const loadNewConversionRate = async () => {
            if (!provider || !offer || !offer.takerAssets.currencies[0]) {
                setAmountUsdFormatted(RATE_UNKNOWN);
                return;
            }

            try {
                let usdFormatted = await getConversionFormatted(chainInfos, provider, [currencyInfo.address, getCurrencyByName(chainInfos, "USDC").address], offer.takerAssets.currencies[0].currencyAmount);
                setAmountUsdFormatted(usdFormatted);
            } catch (error) {
                console.error("Error fetching conversion rate:", error);
                setAmountUsdFormatted(RATE_UNKNOWN);
            }
        };

        loadNewConversionRate();
    }, [provider, offer, currencyInfo]);

    useEffect(() => {
        const loadCurrencyInfos = async () => {
            if (!provider || !offer) {
                setCurrencyInfo({});
                return;
            }

            let sellCurrencyInfo = getCurrencyByAddress(chainInfos, offer.takerAssets.currencies[0].currencyAddress);
            if (sellCurrencyInfo) {
                setCurrencyInfo(sellCurrencyInfo);
            } else {
                const currencyContract = new ethers.Contract(offer.takerAssets.currencies[0].currencyAddress, ERC_20_ABI, provider);
                setCurrencyInfo({
                    name: await currencyContract.name(),
                    decimals: await currencyContract.decimals(),
                    image: "/icon/gold-coin.svg",
                    isCustom: true,
                    address: offer.takerAssets.currencies[0].currencyAddress
                });
            }
        }

        loadCurrencyInfos();
    }, [provider, offer]);

    useEffect(() => {
        const loadHoneypotIsResults = async () => {
            if (!currencyInfo || !currencyInfo.isCustom) {
                setHoneypotIsResult({});
                return;
            }

            try {
                const honeypotData = await fetchHoneypotIsData(currencyInfo.address, chainInfos);
                setHoneypotIsResult(honeypotData);
            } catch (error) {
                console.error("Error fetching HoneypotIs result:", error);
                setHoneypotIsResult({});
            }
        };

        loadHoneypotIsResults();
    }, [currencyInfo]);

    const handleBuyClick = async () => {
        setAcceptOfferModalShow(true);
    };

    const handleWithdrawOfferClick = async () => {
        setWithdrawOfferModalShow(true);
    };

    const reservedForOtherAddress = () => {
        if (!address) {
            return true;
        }
        if (offer.reservedForAddress === ethers.ZeroAddress) {
            return false;
        }
        return offer.reservedForAddress !== address;
    };

    const isMaker = () => {
        if (!address) {
            return false;
        }
        return offer.makerAddress === address;
    };

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast.success('Copied to clipboard!', {autoClose: 2000});
        } catch (err) {
            toast.error('Failed to copy!', {autoClose: 2000});
        }
    };

    if (!address) {
        return <ConnectedWalletRequired/>;
    }

    if (!offer) {
        return (<div className="container mt-4 text-center">
            <h1>Offer not found</h1>
            <p>Unfortunately the offer <strong>{offerId}</strong> wasn't found. Please check the offer ID is correct and
                the trade is still active.</p>
        </div>);
    }

    let sellCurrencyAmountFormatted = "";
    if (currencyInfo) {
        sellCurrencyAmountFormatted = formatStringTwoDecimals(ethers.formatUnits(offer.takerAssets.currencies[0].currencyAmount, currencyInfo.decimals));
    }

    return (
        <div className="container mt-4"
             style={{paddingBottom: '100px'}}> {/* Add padding at bottom to account for the fixed bottom section */}
            <h1 className="text-center mb-4">View Offer</h1>
            <div className="d-flex justify-content-center">
                <p className="text-center mb-3">Viewing the
                    offer <strong>{offerId}</strong> by {isMaker() ? "you" : offer.makerAddress}</p>
            </div>
            {isMaker() && (
                <div>
                    <Alert variant="info" className="d-flex flex-wrap word-wrap">
                        <img src="/illustration/social-bg.svg" alt="Hand with crossed out offer over it"
                             className="illustration-medium me-3 ms-1"/>
                        <div>
                            <Alert.Heading>Your offer</Alert.Heading>
                            <p className="small-hint">You made this offer!</p>
                            <span>Send this URL to {offer.reservedForAddress !== ethers.ZeroAddress ? `the owner of ${offer.reservedForAddress}` : "the buyer"} so they can view and accept the offer:</span>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <span>{window.location.href}</span>
                                <FaCopy
                                    onClick={() => copyToClipboard(window.location.href)}
                                    style={{marginLeft: '10px', cursor: 'pointer'}}
                                    title="Copy URL to clipboard"/>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip className="qr-tooltip" id="tooltip-qr">
                                            <div className="qr-tooltip-container">
                                                <QRCodeSVG value={window.location.href} size="180" bgColor="#ffffff"
                                                           imageSettings={{
                                                               src: "/logo_black.svg",
                                                               height: 35,
                                                               width: 35,
                                                               excavate: true,
                                                           }}/>
                                            </div>
                                        </Tooltip>
                                    }>
                                    <span><FaQrcode style={{marginLeft: '10px'}}/></span>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </Alert>
                </div>
            )}
            {offer.reservedForAddress === address && (
                <div>
                <Alert variant="success" className="d-flex align-items-center">
                        <img src="/illustration/vip-pass-bg.svg" alt="VIP pass"
                             className="illustration-small me-2"/>
                        <div>
                            <Alert.Heading>Reserved For You!</Alert.Heading>
                            <p className="m-0">
                                The person who created this offer reserved it just for you!
                            </p>
                        </div>
                    </Alert>
                </div>
            )}
            {!isMaker() && reservedForOtherAddress() && (
                <div>
                    <Alert variant="info">
                        <Alert.Heading>Offer Reserved</Alert.Heading>
                        <p>
                            The person who created this offer reserved it for {offer.reservedForAddress}<br/>
                            If you also own that address please connect that wallet.
                        </p>
                    </Alert>
                </div>
            )}

            <h2>{isMaker() ? "They give you" : "You give"}</h2>
            <div className="container mt-4">
                <p>{sellCurrencyAmountFormatted} {currencyInfo.name}<small>{currencyInfo.isCustom ? ` (${currencyInfo.address}` : ""}{currencyInfo.isCustom && <FaCopy
                    onClick={() => copyToClipboard(currencyInfo.address)}
                    style={{marginLeft: '8px', cursor: 'pointer'}}
                    title="Copy token address to clipboard"/>}{currencyInfo.isCustom ? `)` : ""}</small><br />
                    <span className="conversion-rate-hint">(≈ {amountUsdFormatted} USD)</span>
                    {honeypotIsResult && honeypotIsResult['summary'] && honeypotIsResult['summary']['risk'] !== 'low' && (
                        <Alert variant="warning" className="mt-2">
                            <Alert.Heading>Token Security</Alert.Heading>
                            <p className="mb-0">The third party provider Honeypot.is has given this token a risk level of <strong>{honeypotIsResult['summary']['risk']}</strong> with a rating of {honeypotIsResult['summary']['riskLevel']}/100. <a href={createLinkToHoneypotIsWebsite(currencyInfo.address, chainInfos)} target="_blank" rel="noopener noreferrer">View details</a><br />
                                Proceed at your own risk.</p>
                        </Alert>
                    )}
                    {currencyInfo.isCustom && !chainInfos.abilities.honeypot_is && (
                        <Alert variant="warning" className="mt-2">
                            <Alert.Heading>Token Security</Alert.Heading>
                            <p className="mb-0">We can't yet perform a security analysis for tokens on {chainInfos.name} to check if it's is safe or e.g. has any taxes.<br />
                                Proceed at your own risk.</p>
                        </Alert>
                    )}
                </p>
                <NftCards nftsRaw={offer.takerAssets.nfts}/>
            </div>
            <h2>{isMaker() ? "You give them" : "You get"}</h2>
            <NftCards nftsRaw={offer.makerAssets.nfts}/>

            {isMaker() && (
                <div className="d-flex justify-content-between align-items-center fixed-bottom p-3 bg-white border-top">
                    <div>
                        <p>You are selling <strong>
                            {offer.makerAssets.nfts.length} NFT{offer.makerAssets.nfts.length === 1 ? "" : "s"} for {sellCurrencyAmountFormatted} {currencyInfo.name}</strong>
                        </p>
                    </div>
                    <div>
                        <button
                            onClick={handleWithdrawOfferClick}
                            className="btn btn-warning btn-lg">Withdraw offer...
                        </button>
                        <WithdrawOfferModal
                            offerId={offerId}
                            offer={offer}
                            withdrawOfferModalShow={withdrawOfferModalShow}
                            setWithdrawOfferModalShow={setWithdrawOfferModalShow}
                            sellCurrencyAmountFormatted={sellCurrencyAmountFormatted}
                            sellCurrencyAmountUsdFormatted={amountUsdFormatted}
                            sellCurrencyInfo={currencyInfo}
                        />
                    </div>
                </div>
            )}
            {!isMaker() && !reservedForOtherAddress() && (
                <div className="d-flex justify-content-between align-items-center fixed-bottom p-3 bg-white border-top">
                    <div>
                        <p>You are buying <strong>
                            {offer.makerAssets.nfts.length} NFT{offer.makerAssets.nfts.length === 1 ? "" : "s"} for {sellCurrencyAmountFormatted} {currencyInfo.name}</strong>
                        </p>
                    </div>
                    <div>
                        <button
                            onClick={handleBuyClick}
                            className="btn btn-primary btn-lg">Buy...
                        </button>
                        <AcceptOfferModal
                            offerId={offerId}
                            offer={offer}
                            modalShow={acceptOfferModalShow}
                            setModalShow={setAcceptOfferModalShow}
                            sellCurrencyAmountFormatted={sellCurrencyAmountFormatted}
                            sellCurrencyAmountUsdFormatted={amountUsdFormatted}
                            sellCurrencyInfo={currencyInfo}
                        />
                    </div>
                </div>
            )}
            <ToastContainer/>
        </div>
    );
}

export default ViewOffer;
