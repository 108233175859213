import {ethers} from "ethers";
import axios from 'axios';
import {ERC_721_ABI} from "../constants";
import {getAbsoluteUrl} from "./urlUtils";

export const enrichNfts = async (chainId, nftsRaw) => {
    if (!nftsRaw || !chainId) {
        return [];
    }

    try {
        const enrichedNfts = [];
        for (let nft of nftsRaw) {
            const requestUrl = getAbsoluteUrl(`/api/nft/${chainId}/${nft.collectionAddress}/${nft.tokenId}`);
            const response = await axios.get(requestUrl);
            const data = response.data;

            enrichedNfts.push({
                collectionAddress: nft.collectionAddress,
                collectionName: data.contract.name,
                tokenId: typeof nft.tokenId === "bigint" ? nft.tokenId.toString() : nft.tokenId,
                name: data.title,
                thumbnail: findThumbnail(data),
            });
        }

        return enrichedNfts;
    } catch (error) {
        console.error(`Error enriching NFTs with metadata:`, error);
        return [];
    }
};

export const getNftsForOwner = async (chainId, owner) => {
    const requestUrl = getAbsoluteUrl(`/api/nftsForOwner/${chainId}/${owner}/`);
    const response = await axios.get(requestUrl);
    return response.data;
}

export const findThumbnail = (nft) => {
    return nft.image?.cachedUrl ?? nft.image?.originalUrl ?? nft.rawMetadata?.image ?? nft.contract?.openSeaMetadata?.imageUrl ?? "/genericNft.jpg";
}

export const collectionApprovedForAll = async (chainInfos, provider, address, collectionAddress) => {
    const collectionContract = new ethers.Contract(collectionAddress, ERC_721_ABI, provider);
    return await collectionContract.isApprovedForAll(address, chainInfos.contracts.DealGuardian);
}