const deployedAddresses = require('deployed_addresses.json');

const ALCHEMY_URL_MOCK = "Mock";

const WALLET_CONNECT_PROJECT_ID = "227fd1bec4de7580a1f02e1edcd11821";

const CHAINS = [
    {
        name: "Arbitrum One",
        icon: "/icon/arbitrum.png",
        chainId: 42161,
        alchemyUrl: "https://arb-mainnet.g.alchemy.com/v2/", // Note: the Alchemy URLs have to be kept in sync with hardhat.config.js
        rpcUrls: ["https://arbitrum.llamarpc.com"],
        blockExplorerUrls: ["https://arbiscan.io/"],
        explorerUrlTemplate: "https://arbiscan.io/nft/{contractAddress}/{tokenId}",
        explorerIcon: "/icon/scan.svg",
        marketUrlTemplate: "https://opensea.io/assets/arbitrum/{contractAddress}/{tokenId}",
        marketIcon: "/icon/os.svg",
        testnet: false,
        nativeCurrency: {
            decimals: 18,
            name: "ETH",
            symbol: "ETH",
        },
        currencies: [
            {
                name: "wETH",
                address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
                decimals: 18,
                image: "./icon/weth.png",
            },
            {
                name: "USDC",
                address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
                decimals: 6,
                image: "./icon/usdc.png",
            },
        ],
        contracts: {
            DealGuardian: "0x239cC5fC1d85aa333aF2f45D3287178Ab7D46C7f",
            UniswapV2Router: "0x4752ba5dbc23f44d87826276bf6fd6b1c372ad24",
        },
        abilities: {
            honeypot_is: false,
        },
    },
    {
        name: "Base",
        icon: "/icon/base.png",
        chainId: 8453,
        alchemyUrl: "https://base-mainnet.g.alchemy.com/v2/",
        rpcUrls: ["https://base.llamarpc.com"],
        blockExplorerUrls: ["https://basescan.org"],
        explorerUrlTemplate: "https://basescan.org/nft/{contractAddress}/{tokenId}",
        explorerIcon: "/icon/scan.svg",
        marketUrlTemplate: "https://opensea.io/assets/base/{contractAddress}/{tokenId}",
        marketIcon: "/icon/os.svg",
        testnet: false,
        nativeCurrency: {
            decimals: 18,
            name: "ETH",
            symbol: "ETH",
        },
        currencies: [
            {
                name: "wETH",
                address: "0x4200000000000000000000000000000000000006",
                decimals: 18,
                image: "./icon/weth.png",
            },
            {
                name: "USDC",
                address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
                decimals: 6,
                image: "./icon/usdc.png",
            },
        ],
        contracts: {
            DealGuardian: "0x239cC5fC1d85aa333aF2f45D3287178Ab7D46C7f",
            UniswapV2Router: "0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24",
        },
        abilities: {
            honeypot_is: true,
        },
    },
    {
        name: "Ethereum",
        icon: "/icon/ethereum.png",
        chainId: 1,
        alchemyUrl: "https://eth-mainnet.g.alchemy.com/v2/",
        rpcUrls: ["https://eth.llamarpc.com"],
        blockExplorerUrls: ["https://etherscan.io"],
        explorerUrlTemplate: "https://etherscan.io/nft/{contractAddress}/{tokenId}",
        explorerIcon: "/icon/scan.svg",
        marketUrlTemplate: "https://opensea.io/assets/ethereum/{contractAddress}/{tokenId}",
        marketIcon: "/icon/os.svg",
        testnet: false,
        nativeCurrency: {
            decimals: 18,
            name: "ETH",
            symbol: "ETH",
        },
        currencies: [
            {
                name: "wETH",
                address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
                decimals: 18,
                image: "./icon/weth.png",
            },
            {
                name: "USDC",
                address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
                decimals: 6,
                image: "./icon/usdc.png",
            },
        ],
        contracts: {
            DealGuardian: "0x239cC5fC1d85aa333aF2f45D3287178Ab7D46C7f",
            UniswapV2Router: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
        },
        abilities: {
            honeypot_is: true,
        },
    },
    ////////// Testnets //////////
    {
        name: "Ethereum_Sepolia",
        icon: "/icon/ethereum.png",
        chainId: 11155111,
        alchemyUrl: "https://eth-sepolia.g.alchemy.com/v2/",
        rpcUrls: ["https://ethereum-sepolia-rpc.publicnode.com"],
        blockExplorerUrls: ["https://sepolia.etherscan.io"],
        explorerUrlTemplate: "https://sepolia.etherscan.io/nft/{contractAddress}/{tokenId}",
        explorerIcon: "/icon/scan.svg",
        marketUrlTemplate: "https://testnets.opensea.io/assets/sepolia/{contractAddress}/{tokenId}",
        marketIcon: "/icon/os.svg",
        testnet: true,
        nativeCurrency: {
            decimals: 18,
            name: "ETH",
            symbol: "ETH",
        },
        currencies: [
            {
                name: "wETH",
                address: "0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9",
                decimals: 18,
                image: "./icon/weth.png",
            },
            {
                name: "USDC",
                address: "0x7169D38820dfd117C3FA1f22a697dBA58d90BA06",
                decimals: 6,
                image: "./icon/usdc.png",
            },
        ],
        contracts: {
            DealGuardian: "0x4CBAAE5b607EA866380D1Cb41fCF04DbE144FB24",
            UniswapV2Router: "0xC532a74256D3Db42D0Bf7a0400fEFDbad7694008",
        },
        abilities: {
            honeypot_is: false,
        },
    },
    {
        name: "Hardhat",
        icon: "/icon/hardhat.png",
        chainId: 31337,
        alchemyUrl: ALCHEMY_URL_MOCK,
        rpcUrls: ["http://127.0.0.1:8545"],
        blockExplorerUrls: null,
        explorerUrlTemplate: "https://basescan.org/nft/{contractAddress}/{tokenId}",
        explorerIcon: "/icon/scan.svg",
        marketUrlTemplate: "https://opensea.io/assets/base/{contractAddress}/{tokenId}",
        marketIcon: "/icon/os.svg",
        testnet: true,
        nativeCurrency: {
            decimals: 18,
            name: "ETH",
            symbol: "ETH",
        },
        currencies: [
            {
                name: "wETH",
                address: deployedAddresses['SampleSetup#WrappedEther'],
                decimals: 18,
                image: "./icon/weth.png",
            },
            {
                name: "USDC",
                address: deployedAddresses['SampleSetup#USDC'],
                decimals: 6,
                image: "./icon/usdc.png",
            },
        ],
        contracts: {
            DealGuardian: deployedAddresses['SampleSetup#DealGuardian'],
            UniswapV2Router: deployedAddresses['SampleSetup#MockUniswapV2Router'],
        },
        abilities: {
            honeypot_is: false,
        },
    },
];


const DEAL_GUARDIAN_ABI = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_uniswapRouter",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_wrappedNativeCoin",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_feesCurrencyAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_feesCurrencyAmount",
                "type": "uint256"
            },
            {
                "internalType": "uint8",
                "name": "_refundPercent",
                "type": "uint8"
            },
            {
                "internalType": "bool",
                "name": "_convertFeesToToken",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "AddressCantBeZero",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "AmountCantBeZero",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "EnforcedPause",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ExpectedPause",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "InsufficientFees",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "Max100Percent",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MoreThanBalance",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "NftOwnershipVerificationFailed",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "offerId",
                "type": "string"
            }
        ],
        "name": "OfferNotFound",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "offerId",
                "type": "string"
            }
        ],
        "name": "OfferReserved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "OnlyMakerCanWithdraw",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "OwnableInvalidOwner",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "OwnableUnauthorizedAccount",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ReentrancyGuardReentrantCall",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferringAssetsFailed",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "string",
                "name": "offerId",
                "type": "string"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "acceptedByAddress",
                "type": "address"
            }
        ],
        "name": "OfferAccepted",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "string",
                "name": "offerId",
                "type": "string"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "makerAddress",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "reservedForAddress",
                "type": "address"
            }
        ],
        "name": "OfferMade",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "string",
                "name": "offerId",
                "type": "string"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "makerAddress",
                "type": "address"
            }
        ],
        "name": "OfferWithdrawn",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Paused",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Unpaused",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "offerId",
                "type": "string"
            }
        ],
        "name": "acceptOffer",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "convertFeesToToken",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "feeConfigurations",
        "outputs": [
            {
                "internalType": "address",
                "name": "currencyAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "currencyAmount",
                "type": "uint256"
            },
            {
                "internalType": "uint8",
                "name": "refundPercent",
                "type": "uint8"
            },
            {
                "internalType": "uint256",
                "name": "timestamp",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "feeRefundPercent",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "feesCurrencyAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "feesCurrencyAmount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getActiveOfferIds",
        "outputs": [
            {
                "internalType": "string[]",
                "name": "",
                "type": "string[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getMakeOfferFeesInWei",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "offerId",
                "type": "string"
            }
        ],
        "name": "getOffer",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "makerAddress",
                        "type": "address"
                    },
                    {
                        "components": [
                            {
                                "components": [
                                    {
                                        "internalType": "address",
                                        "name": "collectionAddress",
                                        "type": "address"
                                    },
                                    {
                                        "internalType": "uint256",
                                        "name": "tokenId",
                                        "type": "uint256"
                                    }
                                ],
                                "internalType": "struct DealGuardian.NftDescriptor[]",
                                "name": "nfts",
                                "type": "tuple[]"
                            },
                            {
                                "components": [
                                    {
                                        "internalType": "address",
                                        "name": "currencyAddress",
                                        "type": "address"
                                    },
                                    {
                                        "internalType": "uint256",
                                        "name": "currencyAmount",
                                        "type": "uint256"
                                    }
                                ],
                                "internalType": "struct DealGuardian.TokenAmount[]",
                                "name": "currencies",
                                "type": "tuple[]"
                            }
                        ],
                        "internalType": "struct DealGuardian.OfferAssets",
                        "name": "makerAssets",
                        "type": "tuple"
                    },
                    {
                        "components": [
                            {
                                "components": [
                                    {
                                        "internalType": "address",
                                        "name": "collectionAddress",
                                        "type": "address"
                                    },
                                    {
                                        "internalType": "uint256",
                                        "name": "tokenId",
                                        "type": "uint256"
                                    }
                                ],
                                "internalType": "struct DealGuardian.NftDescriptor[]",
                                "name": "nfts",
                                "type": "tuple[]"
                            },
                            {
                                "components": [
                                    {
                                        "internalType": "address",
                                        "name": "currencyAddress",
                                        "type": "address"
                                    },
                                    {
                                        "internalType": "uint256",
                                        "name": "currencyAmount",
                                        "type": "uint256"
                                    }
                                ],
                                "internalType": "struct DealGuardian.TokenAmount[]",
                                "name": "currencies",
                                "type": "tuple[]"
                            }
                        ],
                        "internalType": "struct DealGuardian.OfferAssets",
                        "name": "takerAssets",
                        "type": "tuple"
                    },
                    {
                        "internalType": "address",
                        "name": "reservedForAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "offerMadeTimestamp",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct DealGuardian.Offer",
                "name": "",
                "type": "tuple"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "participant",
                "type": "address"
            },
            {
                "internalType": "enum DealGuardian.FilterType",
                "name": "filterType",
                "type": "uint8"
            }
        ],
        "name": "getOffers",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "offerId",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "makerAddress",
                        "type": "address"
                    },
                    {
                        "components": [
                            {
                                "components": [
                                    {
                                        "internalType": "address",
                                        "name": "collectionAddress",
                                        "type": "address"
                                    },
                                    {
                                        "internalType": "uint256",
                                        "name": "tokenId",
                                        "type": "uint256"
                                    }
                                ],
                                "internalType": "struct DealGuardian.NftDescriptor[]",
                                "name": "nfts",
                                "type": "tuple[]"
                            },
                            {
                                "components": [
                                    {
                                        "internalType": "address",
                                        "name": "currencyAddress",
                                        "type": "address"
                                    },
                                    {
                                        "internalType": "uint256",
                                        "name": "currencyAmount",
                                        "type": "uint256"
                                    }
                                ],
                                "internalType": "struct DealGuardian.TokenAmount[]",
                                "name": "currencies",
                                "type": "tuple[]"
                            }
                        ],
                        "internalType": "struct DealGuardian.OfferAssets",
                        "name": "makerAssets",
                        "type": "tuple"
                    },
                    {
                        "components": [
                            {
                                "components": [
                                    {
                                        "internalType": "address",
                                        "name": "collectionAddress",
                                        "type": "address"
                                    },
                                    {
                                        "internalType": "uint256",
                                        "name": "tokenId",
                                        "type": "uint256"
                                    }
                                ],
                                "internalType": "struct DealGuardian.NftDescriptor[]",
                                "name": "nfts",
                                "type": "tuple[]"
                            },
                            {
                                "components": [
                                    {
                                        "internalType": "address",
                                        "name": "currencyAddress",
                                        "type": "address"
                                    },
                                    {
                                        "internalType": "uint256",
                                        "name": "currencyAmount",
                                        "type": "uint256"
                                    }
                                ],
                                "internalType": "struct DealGuardian.TokenAmount[]",
                                "name": "currencies",
                                "type": "tuple[]"
                            }
                        ],
                        "internalType": "struct DealGuardian.OfferAssets",
                        "name": "takerAssets",
                        "type": "tuple"
                    },
                    {
                        "internalType": "address",
                        "name": "reservedForAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "offerMadeTimestamp",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct DealGuardian.OfferWithId[]",
                "name": "",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "offerId",
                "type": "string"
            }
        ],
        "name": "getRefundAmountForWithdrawing",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "currencyAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "currencyAmount",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct DealGuardian.TokenAmount",
                "name": "",
                "type": "tuple"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "components": [
                            {
                                "internalType": "address",
                                "name": "collectionAddress",
                                "type": "address"
                            },
                            {
                                "internalType": "uint256",
                                "name": "tokenId",
                                "type": "uint256"
                            }
                        ],
                        "internalType": "struct DealGuardian.NftDescriptor[]",
                        "name": "nfts",
                        "type": "tuple[]"
                    },
                    {
                        "components": [
                            {
                                "internalType": "address",
                                "name": "currencyAddress",
                                "type": "address"
                            },
                            {
                                "internalType": "uint256",
                                "name": "currencyAmount",
                                "type": "uint256"
                            }
                        ],
                        "internalType": "struct DealGuardian.TokenAmount[]",
                        "name": "currencies",
                        "type": "tuple[]"
                    }
                ],
                "internalType": "struct DealGuardian.OfferAssets",
                "name": "makerAssets",
                "type": "tuple"
            },
            {
                "components": [
                    {
                        "components": [
                            {
                                "internalType": "address",
                                "name": "collectionAddress",
                                "type": "address"
                            },
                            {
                                "internalType": "uint256",
                                "name": "tokenId",
                                "type": "uint256"
                            }
                        ],
                        "internalType": "struct DealGuardian.NftDescriptor[]",
                        "name": "nfts",
                        "type": "tuple[]"
                    },
                    {
                        "components": [
                            {
                                "internalType": "address",
                                "name": "currencyAddress",
                                "type": "address"
                            },
                            {
                                "internalType": "uint256",
                                "name": "currencyAmount",
                                "type": "uint256"
                            }
                        ],
                        "internalType": "struct DealGuardian.TokenAmount[]",
                        "name": "currencies",
                        "type": "tuple[]"
                    }
                ],
                "internalType": "struct DealGuardian.OfferAssets",
                "name": "takerAssets",
                "type": "tuple"
            },
            {
                "internalType": "address",
                "name": "reservedForAddress",
                "type": "address"
            }
        ],
        "name": "makeOffer",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "numFreeFeeRefunds",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "numFreeFeeRefundsUsed",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "pause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "paused",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "tokenAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "recoverFunds",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string[]",
                "name": "offerIds",
                "type": "string[]"
            }
        ],
        "name": "removeOffers",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "currencyAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "currencyAmount",
                "type": "uint256"
            },
            {
                "internalType": "uint8",
                "name": "refundPercent",
                "type": "uint8"
            },
            {
                "internalType": "uint8",
                "name": "numFreeRefunds",
                "type": "uint8"
            },
            {
                "internalType": "bool",
                "name": "convertFees",
                "type": "bool"
            }
        ],
        "name": "setFees",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "uniswapRouter",
        "outputs": [
            {
                "internalType": "contract IUniswapV2Router02",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "unpause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "withdrawFees",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "offerId",
                "type": "string"
            }
        ],
        "name": "withdrawOffer",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "wrappedNativeCoin",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];
const ERC_20_ABI = [
    {
        "constant": true,
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "name": "",
                "type": "string"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_spender",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_from",
                "type": "address"
            },
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {
                "name": "",
                "type": "uint8"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "_owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "name": "balance",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "name": "",
                "type": "string"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "_owner",
                "type": "address"
            },
            {
                "name": "_spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "payable": true,
        "stateMutability": "payable",
        "type": "fallback"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "name": "spender",
                "type": "address"
            },
            {
                "indexed": false,
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    }
];
const ERC_721_ABI = [
    {
    "constant": false,
    "inputs": [{"internalType": "address", "name": "to", "type": "address"}, {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
    }],
    "name": "approve",
    "outputs": [],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "constant": false,
    "inputs": [{"internalType": "address", "name": "to", "type": "address"}, {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
    }],
    "name": "mint",
    "outputs": [],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "constant": false,
    "inputs": [{"internalType": "address", "name": "from", "type": "address"}, {
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
    "name": "safeTransferFrom",
    "outputs": [],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "constant": false,
    "inputs": [{"internalType": "address", "name": "from", "type": "address"}, {
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {"internalType": "uint256", "name": "tokenId", "type": "uint256"}, {
        "internalType": "bytes",
        "name": "_data",
        "type": "bytes"
    }],
    "name": "safeTransferFrom",
    "outputs": [],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "constant": false,
    "inputs": [{"internalType": "address", "name": "to", "type": "address"}, {
        "internalType": "bool",
        "name": "approved",
        "type": "bool"
    }],
    "name": "setApprovalForAll",
    "outputs": [],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "constant": false,
    "inputs": [{"internalType": "address", "name": "from", "type": "address"}, {
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
    "name": "transferFrom",
    "outputs": [],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
}, {"inputs": [], "payable": false, "stateMutability": "nonpayable", "type": "constructor"}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "from", "type": "address"}, {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {"indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256"}],
    "name": "Transfer",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "owner", "type": "address"}, {
        "indexed": true,
        "internalType": "address",
        "name": "approved",
        "type": "address"
    }, {"indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256"}],
    "name": "Approval",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "owner", "type": "address"}, {
        "indexed": true,
        "internalType": "address",
        "name": "operator",
        "type": "address"
    }, {"indexed": false, "internalType": "bool", "name": "approved", "type": "bool"}],
    "name": "ApprovalForAll",
    "type": "event"
}, {
    "constant": true,
    "inputs": [{"internalType": "address", "name": "owner", "type": "address"}],
    "name": "balanceOf",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
}, {
    "constant": true,
    "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
    "name": "getApproved",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
}, {
    "constant": true,
    "inputs": [{"internalType": "address", "name": "owner", "type": "address"}, {
        "internalType": "address",
        "name": "operator",
        "type": "address"
    }],
    "name": "isApprovedForAll",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
}, {
    "constant": true,
    "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
    "name": "ownerOf",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
}, {
    "constant": true,
    "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
    "name": "tokenURI",
    "outputs": [{"internalType": "string", "name": "", "type": "string"}],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
}, {
    "constant": true,
    "inputs": [{"internalType": "bytes4", "name": "interfaceId", "type": "bytes4"}],
    "name": "supportsInterface",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
}];
const UNISWAP_V2_ROUTER_ABI = [
    {
        "inputs": [{
            "internalType": "address",
            "name": "_factory",
            "type": "address"
        }, {"internalType": "address", "name": "_WETH", "type": "address"}],
        "stateMutability": "nonpayable",
        "type": "constructor"
    }, {
        "inputs": [],
        "name": "WETH",
        "outputs": [{"internalType": "address", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "tokenA", "type": "address"}, {
            "internalType": "address",
            "name": "tokenB",
            "type": "address"
        }, {"internalType": "uint256", "name": "amountADesired", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountBDesired",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "amountAMin", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountBMin",
            "type": "uint256"
        }, {"internalType": "address", "name": "to", "type": "address"}, {
            "internalType": "uint256",
            "name": "deadline",
            "type": "uint256"
        }],
        "name": "addLiquidity",
        "outputs": [{"internalType": "uint256", "name": "amountA", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountB",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "liquidity", "type": "uint256"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "token", "type": "address"}, {
            "internalType": "uint256",
            "name": "amountTokenDesired",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "amountTokenMin", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountETHMin",
            "type": "uint256"
        }, {"internalType": "address", "name": "to", "type": "address"}, {
            "internalType": "uint256",
            "name": "deadline",
            "type": "uint256"
        }],
        "name": "addLiquidityETH",
        "outputs": [{"internalType": "uint256", "name": "amountToken", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountETH",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "liquidity", "type": "uint256"}],
        "stateMutability": "payable",
        "type": "function"
    }, {
        "inputs": [],
        "name": "factory",
        "outputs": [{"internalType": "address", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "amountOut", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "reserveIn",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "reserveOut", "type": "uint256"}],
        "name": "getAmountIn",
        "outputs": [{"internalType": "uint256", "name": "amountIn", "type": "uint256"}],
        "stateMutability": "pure",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "amountIn", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "reserveIn",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "reserveOut", "type": "uint256"}],
        "name": "getAmountOut",
        "outputs": [{"internalType": "uint256", "name": "amountOut", "type": "uint256"}],
        "stateMutability": "pure",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "amountOut", "type": "uint256"}, {
            "internalType": "address[]",
            "name": "path",
            "type": "address[]"
        }],
        "name": "getAmountsIn",
        "outputs": [{"internalType": "uint256[]", "name": "amounts", "type": "uint256[]"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "amountIn", "type": "uint256"}, {
            "internalType": "address[]",
            "name": "path",
            "type": "address[]"
        }],
        "name": "getAmountsOut",
        "outputs": [{"internalType": "uint256[]", "name": "amounts", "type": "uint256[]"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "amountA", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "reserveA",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "reserveB", "type": "uint256"}],
        "name": "quote",
        "outputs": [{"internalType": "uint256", "name": "amountB", "type": "uint256"}],
        "stateMutability": "pure",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "tokenA", "type": "address"}, {
            "internalType": "address",
            "name": "tokenB",
            "type": "address"
        }, {"internalType": "uint256", "name": "liquidity", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountAMin",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "amountBMin", "type": "uint256"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
        "name": "removeLiquidity",
        "outputs": [{"internalType": "uint256", "name": "amountA", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountB",
            "type": "uint256"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "token", "type": "address"}, {
            "internalType": "uint256",
            "name": "liquidity",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "amountTokenMin", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountETHMin",
            "type": "uint256"
        }, {"internalType": "address", "name": "to", "type": "address"}, {
            "internalType": "uint256",
            "name": "deadline",
            "type": "uint256"
        }],
        "name": "removeLiquidityETH",
        "outputs": [{"internalType": "uint256", "name": "amountToken", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountETH",
            "type": "uint256"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "token", "type": "address"}, {
            "internalType": "uint256",
            "name": "liquidity",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "amountTokenMin", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountETHMin",
            "type": "uint256"
        }, {"internalType": "address", "name": "to", "type": "address"}, {
            "internalType": "uint256",
            "name": "deadline",
            "type": "uint256"
        }],
        "name": "removeLiquidityETHSupportingFeeOnTransferTokens",
        "outputs": [{"internalType": "uint256", "name": "amountETH", "type": "uint256"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "token", "type": "address"}, {
            "internalType": "uint256",
            "name": "liquidity",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "amountTokenMin", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountETHMin",
            "type": "uint256"
        }, {"internalType": "address", "name": "to", "type": "address"}, {
            "internalType": "uint256",
            "name": "deadline",
            "type": "uint256"
        }, {"internalType": "bool", "name": "approveMax", "type": "bool"}, {
            "internalType": "uint8",
            "name": "v",
            "type": "uint8"
        }, {"internalType": "bytes32", "name": "r", "type": "bytes32"}, {
            "internalType": "bytes32",
            "name": "s",
            "type": "bytes32"
        }],
        "name": "removeLiquidityETHWithPermit",
        "outputs": [{"internalType": "uint256", "name": "amountToken", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountETH",
            "type": "uint256"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "token", "type": "address"}, {
            "internalType": "uint256",
            "name": "liquidity",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "amountTokenMin", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountETHMin",
            "type": "uint256"
        }, {"internalType": "address", "name": "to", "type": "address"}, {
            "internalType": "uint256",
            "name": "deadline",
            "type": "uint256"
        }, {"internalType": "bool", "name": "approveMax", "type": "bool"}, {
            "internalType": "uint8",
            "name": "v",
            "type": "uint8"
        }, {"internalType": "bytes32", "name": "r", "type": "bytes32"}, {
            "internalType": "bytes32",
            "name": "s",
            "type": "bytes32"
        }],
        "name": "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens",
        "outputs": [{"internalType": "uint256", "name": "amountETH", "type": "uint256"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "tokenA", "type": "address"}, {
            "internalType": "address",
            "name": "tokenB",
            "type": "address"
        }, {"internalType": "uint256", "name": "liquidity", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountAMin",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "amountBMin", "type": "uint256"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}, {
            "internalType": "bool",
            "name": "approveMax",
            "type": "bool"
        }, {"internalType": "uint8", "name": "v", "type": "uint8"}, {
            "internalType": "bytes32",
            "name": "r",
            "type": "bytes32"
        }, {"internalType": "bytes32", "name": "s", "type": "bytes32"}],
        "name": "removeLiquidityWithPermit",
        "outputs": [{"internalType": "uint256", "name": "amountA", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountB",
            "type": "uint256"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "amountOut", "type": "uint256"}, {
            "internalType": "address[]",
            "name": "path",
            "type": "address[]"
        }, {"internalType": "address", "name": "to", "type": "address"}, {
            "internalType": "uint256",
            "name": "deadline",
            "type": "uint256"
        }],
        "name": "swapETHForExactTokens",
        "outputs": [{"internalType": "uint256[]", "name": "amounts", "type": "uint256[]"}],
        "stateMutability": "payable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "amountOutMin", "type": "uint256"}, {
            "internalType": "address[]",
            "name": "path",
            "type": "address[]"
        }, {"internalType": "address", "name": "to", "type": "address"}, {
            "internalType": "uint256",
            "name": "deadline",
            "type": "uint256"
        }],
        "name": "swapExactETHForTokens",
        "outputs": [{"internalType": "uint256[]", "name": "amounts", "type": "uint256[]"}],
        "stateMutability": "payable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "amountOutMin", "type": "uint256"}, {
            "internalType": "address[]",
            "name": "path",
            "type": "address[]"
        }, {"internalType": "address", "name": "to", "type": "address"}, {
            "internalType": "uint256",
            "name": "deadline",
            "type": "uint256"
        }],
        "name": "swapExactETHForTokensSupportingFeeOnTransferTokens",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "amountIn", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountOutMin",
            "type": "uint256"
        }, {"internalType": "address[]", "name": "path", "type": "address[]"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
        "name": "swapExactTokensForETH",
        "outputs": [{"internalType": "uint256[]", "name": "amounts", "type": "uint256[]"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "amountIn", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountOutMin",
            "type": "uint256"
        }, {"internalType": "address[]", "name": "path", "type": "address[]"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
        "name": "swapExactTokensForETHSupportingFeeOnTransferTokens",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "amountIn", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountOutMin",
            "type": "uint256"
        }, {"internalType": "address[]", "name": "path", "type": "address[]"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
        "name": "swapExactTokensForTokens",
        "outputs": [{"internalType": "uint256[]", "name": "amounts", "type": "uint256[]"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "amountIn", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountOutMin",
            "type": "uint256"
        }, {"internalType": "address[]", "name": "path", "type": "address[]"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
        "name": "swapExactTokensForTokensSupportingFeeOnTransferTokens",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "amountOut", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountInMax",
            "type": "uint256"
        }, {"internalType": "address[]", "name": "path", "type": "address[]"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
        "name": "swapTokensForExactETH",
        "outputs": [{"internalType": "uint256[]", "name": "amounts", "type": "uint256[]"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "amountOut", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amountInMax",
            "type": "uint256"
        }, {"internalType": "address[]", "name": "path", "type": "address[]"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
        "name": "swapTokensForExactTokens",
        "outputs": [{"internalType": "uint256[]", "name": "amounts", "type": "uint256[]"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {"stateMutability": "payable", "type": "receive"}];

const FILTER_OFFERS_BY_MAKER = 0;
const FILTER_OFFERS_BY_TAKER = 1;

const HOST_LOCALHOST = "localhost:3000";

const CUSTOM_TOKEN = {
    name: "Custom Token",
    address: "0x0000000000000000000000000000000000000000",
    decimals: 18,
    image: "/icon/gold-coin.svg",
    isCustom: true
};

module.exports = {
    DEAL_GUARDIAN_ABI,
    ERC_20_ABI,
    ERC_721_ABI,
    UNISWAP_V2_ROUTER_ABI,
    ALCHEMY_URL_MOCK,
    WALLET_CONNECT_PROJECT_ID,
    CHAINS,
    CUSTOM_TOKEN,
    FILTER_OFFERS_BY_MAKER,
    FILTER_OFFERS_BY_TAKER,
    HOST_LOCALHOST,
};