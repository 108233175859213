import React, {useMemo, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useBlockchain} from "contexts/BlockchainContext";
import Identicon from 'identicon.js';
import {
    Nav,
    Navbar,
    NavDropdown
} from "react-bootstrap";
import {CHAINS} from "../constants";
import {getAddressDisplayName} from "../utils/formatUtils";
import {ZeroAddress} from "ethers";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

const Header = ({ showTestnets }) => {
    const navigate = useNavigate();
    const {chainInfos, connectWallet, disconnectWallet, switchToChain} = useBlockchain();
    const { address } = useWeb3ModalAccount();

    const [logoSrc, setLogoSrc] = useState('/logo_light_blue.svg');

    const handleMouseEnter = () => {
        setLogoSrc('/logo_light.svg');
    };

    const handleMouseLeave = () => {
        setLogoSrc('/logo_light_blue.svg');
    };

    const handleOptionMyOffers = () => {
        navigate("/myOffers");
    };

    const identiconData = useMemo(() => new Identicon(address ? address : ZeroAddress, {
        background: [221, 231, 243, 255], // same values as `body { background-color }` from index.css
        margin:     0.11,
        size:       48,
        saturation: 0.7,
        brightness: 0.6,
        format:     'png'
    }), [address]);

    return (
        <nav className="navbar navbar-expand navbar-light">
            <Link to="/" className="navbar-brand" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <img src={logoSrc} alt="Deal Guardian Logo" className="ms-3"/>
                <div className="header-title-container">
                    <span className="header-title">Deal Guardian</span>
                    <span className="header-slogan">Your Trade, our Guardian Angel!</span>
                </div>
            </Link>
            <div className="d-flex justify-content-end flex-grow-1 pe-3">
                {address && chainInfos ? (
                    <div className="d-flex align-items-center">
                        <span className="me-3 header-wallet-address">{getAddressDisplayName(address)}</span>
                        <Navbar.Toggle aria-controls="header-navbar"/>
                        <Navbar.Collapse id="header-navbar">
                            <Nav>
                                <NavDropdown
                                    id="nav-avatar-dropdown"
                                    menuVariant="dark"
                                    title={
                                        <div className="position-relative">
                                            <img
                                                src={chainInfos.icon}
                                                alt={`${chainInfos.name} icon`}
                                                className="chain-badge-icon chain-badge-not-glowing"
                                            />
                                            <div
                                                className="avatar-image-div avatar-not-glowing"
                                                onMouseEnter={(e) => {
                                                    e.currentTarget.className = 'avatar-image-div avatar-glowing';
                                                    const badgeIcon = e.currentTarget.parentNode.querySelector('.chain-badge-icon');
                                                    if (badgeIcon) {
                                                        badgeIcon.className = 'chain-badge-icon chain-badge-glowing';
                                                    }
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.currentTarget.className = 'avatar-image-div avatar-not-glowing';
                                                    const badgeIcon = e.currentTarget.parentNode.querySelector('.chain-badge-icon');
                                                    if (badgeIcon) {
                                                        badgeIcon.className = 'chain-badge-icon chain-badge-not-glowing';
                                                    }
                                                }}>
                                                <img
                                                    src={`data:image/png;base64,${identiconData.toString()}`}
                                                    alt="Avatar"
                                                    className="avatar-image"
                                                />
                                            </div>
                                        </div>
                                    }>
                                    <NavDropdown.Item onClick={handleOptionMyOffers}>My Offers</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item as="div" disabled className="dropdown-chain-option">Chains</NavDropdown.Item>
                                    {CHAINS
                                        .filter(chain => (showTestnets || chain.testnet === false))
                                        .map((chain) => (
                                        <NavDropdown.Item key={chain.name} className={`dropdown-chain-option${chainInfos.name === chain.name ? " dropdown-chain-option-active" : ""}${chain.testnet ? " dropdown-chain-option-testnet" : ""}`}
                                                          onClick={() => switchToChain(chain.chainId)}>
                                            <img src={chain.icon} className="dropdown-chain-icon"
                                                 alt={`${chain.name} icon`}/>
                                            {chain.name}
                                        </NavDropdown.Item>
                                    ))}
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item onClick={disconnectWallet} className="text-danger">Disconnect Wallet</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                ) : (
                    <button onClick={connectWallet} className="btn btn-outline-login">Connect Wallet</button>
                )}
            </div>
        </nav>
    );
};

export default Header;
