import React, {useEffect, useState} from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ethers} from "ethers";
import {useBlockchain} from "contexts/BlockchainContext";
import NotFoundPage from "components/NotFoundPage";
import {getCurrencyByName} from "../utils/currencyUtils";
import {ERC_20_ABI} from "../constants";
import JSONbig from "json-bigint";
import {formatStringTwoDecimals} from "../utils/formatUtils";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

function Admin() {
    const {contract, signer, chainInfos} = useBlockchain();
    const { address } = useWeb3ModalAccount();

    const [usdcBalance, setUsdcBalance] = useState(0);
    const [recommendedFeeWithdrawalAmount, setRecommendedFeeWithdrawalAmount] = useState(0);
    const [feesCurrencyAmount, setFeesCurrencyAmount] = useState(0);
    const [feesCurrencyInfos, setFeesCurrencyInfos] = useState({});
    const [feesCurrencyRefundPercentage, setFeesCurrencyRefundPercentage] = useState(0);
    const [feesCurrencyNumFreeRefunds, setFeesCurrencyNumFreeRefunds] = useState(0);
    const [feesShouldConvert, setFeesShouldConvert] = useState(true);
    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [newFeesCurrencyAmount, setNewFeesCurrencyAmount] = useState(0);
    const [newFeesCurrencyAddress, setNewFeesCurrencyAddress] = useState("");
    const [newFeesRefundPercentage, setNewFeesRefundPercentage] = useState(0);
    const [newFeesNumFreeRefunds, setNewFeesNumFreeRefunds] = useState(0);
    const [newFeesShouldConvert, setNewFeesShouldConvert] = useState(true);
    const [dealGuardianContractOwner, setDealGuardianContractOwner] = useState("");

    const USDC_INFOS = getCurrencyByName(chainInfos, "USDC");

    useEffect(() => {
        const fetchUsdcBalance = async () => {
            if (!contract || !signer) {
                setUsdcBalance(0);
                setRecommendedFeeWithdrawalAmount(0);
                return;
            }

            try {
                const usdcContract = new ethers.Contract(USDC_INFOS.address, ERC_20_ABI, signer);
                const balance = await usdcContract.balanceOf(chainInfos.contracts.DealGuardian);
                setUsdcBalance(balance);
                const amountToKeepBackForWithdrawals = (await contract.feesCurrencyAmount()) * 10n;
                const amountToWithdraw = balance - amountToKeepBackForWithdrawals
                setRecommendedFeeWithdrawalAmount(amountToWithdraw < 0 ? 0 : amountToWithdraw);
            } catch (error) {
                console.error('Error fetching USDC balance:', error);
            }
        };

        fetchUsdcBalance();
    }, [contract, signer]);

    useEffect(() => {
        const fetchDealGuardianContractOwner = async () => {
            if (!contract) {
                setDealGuardianContractOwner("");
                return;
            }

            try {
                const owner = await contract.owner();
                console.log(`Deal Guardian contract owner: ${owner}`);
                setDealGuardianContractOwner(owner);
            } catch (error) {
                console.error('Error fetching Deal Guardian contract owner:', error);
            }
        };

        fetchDealGuardianContractOwner();
    }, [contract]);

    useEffect(() => {
        const fetchFees = async () => {
            if (!contract || !signer) {
                setFeesCurrencyAmount(0);
                setFeesCurrencyInfos({});
                setFeesCurrencyRefundPercentage(0);
                setFeesCurrencyNumFreeRefunds(0);
                setFeesShouldConvert(true);
                return;
            }

            try {
                const feesCurrencyAmount = await contract.feesCurrencyAmount();
                const feesCurrencyAddress = await contract.feesCurrencyAddress();
                const feesCurrencyRefundPercentage = await contract.feeRefundPercent();
                const feesCurrencyNumFreeRefunds = await contract.numFreeFeeRefunds();
                const feesShouldConvert = await contract.convertFeesToToken();
                // console.log(`Fees: ${feesCurrencyAmount} of ${feesCurrencyAddress} . Refund Percentage: ${feesCurrencyRefundPercentage} with ${feesCurrencyNumFreeRefunds} free refunds. Should convert: ${feesShouldConvert}`);

                const currencyContract = new ethers.Contract(feesCurrencyAddress, ERC_20_ABI, signer);
                const currencyInfos = {
                    name: await currencyContract.name(),
                    address: feesCurrencyAddress,
                    decimals: await currencyContract.decimals()
                }
                // console.log(`Currency infos: ${JSONbig.stringify(currencyInfos, null, 2)}`);

                setFeesCurrencyAmount(feesCurrencyAmount);
                setFeesCurrencyInfos(currencyInfos);
                setFeesCurrencyRefundPercentage(feesCurrencyRefundPercentage);
                setFeesCurrencyNumFreeRefunds(feesCurrencyNumFreeRefunds);
                setFeesShouldConvert(feesShouldConvert);
            } catch (error) {
                console.error('Error fetching fees:', error);
            }
        };

        fetchFees();
    }, [contract, signer]);

    if (!address || !dealGuardianContractOwner || address !== dealGuardianContractOwner) {
        return <NotFoundPage />;
    }

    const handleDebugClick = async () => {
        console.log(`Debugging...`);

        const paused = await contract.paused();
        console.log(`Deal Guardian contract Paused: ${paused}`);
        const offer = await contract.getOffer("b335ac7ab90b030f969e1c40d86337093e1412bf");
        console.log(`Offer: ${offer}`);
        console.log(`Maker: ${offer.makerAddress}`);
    };

    const handleWithdrawFeesClick = async () => {
        console.log(`Withdrawing fees ${withdrawalAmount} of ${usdcBalance} fees`);
        try {
            const tx = await contract.withdrawFees(withdrawalAmount);
            await tx.wait();
            console.log(`Withdrew fees successfully`);
            toast.success('Withdrew fees successfully', { autoClose: 2000 });
        } catch (e) {
            console.error(`Withdrawal of fees failed:`, e);
            toast.error('Withdrawal failed!', { autoClose: 2000 });
        }
    };

    const handleChangeFeesClick = async () => {
        console.log(`Changing fees to ${newFeesCurrencyAmount} of ${newFeesCurrencyAddress}`);
        try {
            const tx = await contract.setFees(newFeesCurrencyAddress, newFeesCurrencyAmount, newFeesRefundPercentage, newFeesNumFreeRefunds, newFeesShouldConvert);
            await tx.wait();
            console.log(`Changed fees successfully`);
            toast.success('Fees changed successfully', { autoClose: 2000 });
        } catch (e) {
            console.error(`Changing fees failed:`, e);
            toast.error('Changing fees failed!', { autoClose: 2000 });
        }
    };

    const handleWithdrawalAmountChanged = (event) => {
        setWithdrawalAmount(event.target.value);
    };

    const handleNewFeesAmountChanged = (event) => {
        setNewFeesCurrencyAmount(event.target.value);
    };

    const handleNewFeesAddressChanged = (event) => {
        setNewFeesCurrencyAddress(event.target.value);
    };

    const handleNewFeesRefundPercentageChanged = (event) => {
        setNewFeesRefundPercentage(event.target.value);
    };

    const handleNewFeesNumFreeRefundsChanged = (event) => {
        setNewFeesNumFreeRefunds(event.target.value);
    };

    const handleNewFeesShouldConvertChanged = (event) => {
        setNewFeesShouldConvert(event.target.checked);
    };

    return (
        <div className="container my-4">
            <h1 className="text-center mb-4">Admin page</h1>
            <div className="d-flex flex-column align-items-center mt-5">
                <button onClick={handleDebugClick} className="btn btn-primary btn-lg align-items-center">Debug</button>
            </div>
            <div className="d-flex flex-column align-items-center mt-5">
                <h2>Withdraw Fees</h2>
                <p>USDC Balance: {usdcBalance.toString()}<br />
                USDC Balance formatted: {formatStringTwoDecimals(ethers.formatUnits(usdcBalance, USDC_INFOS.decimals))}<br />
                Recommended max fees withdrawal: {recommendedFeeWithdrawalAmount.toString()}</p>
                <div className="col-md-6">
                    <div className="input-group">
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Enter withdrawal amount (wei)"
                            step="1"
                            onChange={handleWithdrawalAmountChanged} />
                        <button onClick={handleWithdrawFeesClick} className="btn btn-primary">Withdraw Fees</button>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center mt-5">
                <h2>Change Fees</h2>
                <p>Current fees: {feesCurrencyAmount.toString()} of {feesCurrencyInfos.address}<br />
                Current fees formatted: {ethers.formatUnits(feesCurrencyAmount, feesCurrencyInfos.decimals)} of {feesCurrencyInfos.name}<br /><br />
                Refund percentage: {feesCurrencyRefundPercentage.toString()}%<br />
                Num free refunds: {feesCurrencyNumFreeRefunds.toString()}<br />
                Should convert: {feesShouldConvert.toString()}</p>
                <div className="col-md-6">
                    <input
                        type="number"
                        className="form-control"
                        placeholder="Enter amount (wei)"
                        step="1"
                        onChange={handleNewFeesAmountChanged}
                    />
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter currency address"
                        onChange={handleNewFeesAddressChanged}
                    />
                    <input
                        type="number"
                        className="form-control"
                        placeholder="Refund %"
                        step="1"
                        onChange={handleNewFeesRefundPercentageChanged}
                    />
                    <input
                        type="number"
                        className="form-control"
                        placeholder="Num free refunds"
                        step="1"
                        onChange={handleNewFeesNumFreeRefundsChanged}
                    />
                    <span>Should convert:</span>
                    <input
                        type="checkbox"
                        className="form-check"
                        checked={newFeesShouldConvert}
                        onChange={handleNewFeesShouldConvertChanged}
                    />
                    <button onClick={handleChangeFeesClick} className="btn btn-secondary">Change</button>
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
}

export default Admin;
