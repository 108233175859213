import React from 'react';
import {Link, useParams} from 'react-router-dom';

function OfferAccepted() {
    const { offerId } = useParams();

    return (
        <div className="container mt-4 text-center">
            <h1 className="text-center mb-0">Trade successful!</h1>
            <img src="/illustration/handshake.svg" alt="Success" className="illustration-large"/>
            <p className="text-lg">Congratulations!</p>
            <p>You accepted the offer {offerId}</p>
            <div className="d-flex justify-content-center">
                <Link to="/" className="btn-link mx-1">go to My Offers</Link>
            </div>
        </div>
    );
}

export default OfferAccepted;
