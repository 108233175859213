import React, {useEffect, useRef, useState} from 'react';
import {CUSTOM_TOKEN} from "../constants";
import {useBlockchain} from "../contexts/BlockchainContext";

const CryptoCurrencyDropdown = ({ selectedCurrency, handleSelectedCurrencyChange }) => {
    const {chainInfos} = useBlockchain();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleSelectCurrency = (newCurrency) => {
        handleSelectedCurrencyChange(newCurrency);
        setDropdownOpen(false);
    };

    const toggleDropdown = () => {
        setDropdownOpen(prevState => !prevState);
    };

    // Handle click outside to close dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    if (!selectedCurrency || !chainInfos) {
        return <p>Loading...</p>
    }

    return (
        <div className="cryptocurrency-dropdown input-group-prepend" ref={dropdownRef}>
            <div className="selected-option form-control" onClick={toggleDropdown}>
                <img src={selectedCurrency.image} alt={selectedCurrency.name}/>
                {selectedCurrency.isCustom ? "Custom" : selectedCurrency.name}
                <span className="caret-symbol ms-2">&#9662;</span>
            </div>
            {dropdownOpen && (
                <ul className="cryptocurrency-dropdown-menu show">
                    {chainInfos.currencies.map(currency => (
                        <li key={currency.address} onClick={() => handleSelectCurrency(currency)}>
                            <img src={currency.image} alt={currency.name}/>
                            {currency.name}
                        </li>
                    ))}
                    <hr className="cryptocurrency-dropdown-divider" />
                    <li key="customToken" onClick={() => handleSelectCurrency(CUSTOM_TOKEN)}>
                        <img src={CUSTOM_TOKEN.image} alt="Generic Currency Icon"/>
                        Custom
                    </li>
                </ul>
            )}
        </div>
    );
};

export default CryptoCurrencyDropdown;
