import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {getTransactionStatusSymbol} from "../utils/transactionUtils";
import {TransactionStatus} from "../enums";
import {collectionApprovedForAll} from "../utils/nftUtils";
import {useBlockchain} from "../contexts/BlockchainContext";
import {useNavigate} from "react-router-dom";
import {ethers} from "ethers";
import { ERC_20_ABI, ERC_721_ABI } from "../constants";
import OfferSummary from "./OfferSummary";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

const MakeOfferModal = ({makeOfferModalShow, setMakeOfferModalShow, selectedNfts, uniqueCollectionValues, sellAmount, sellAmountUsdFormatted, sellCurrency, buyerAddress}) => {
    const {provider, signer, chainInfos, contract} = useBlockchain();
    const { address } = useWeb3ModalAccount();
    const navigate = useNavigate();

    const [offerTransactionStatus, setOfferTransactionStatus] = useState(TransactionStatus.PENDING);
    const [collectionApprovalStatus, setCollectionApprovalStatus] = useState({});
    const [feesCurrencyAmount, setFeesCurrencyAmount] = useState(0);
    const [feesCurrencyInfos, setFeesCurrencyInfos] = useState({});

    useEffect(() => {
        const loadCollectionApprovalStatus = async () => {
            const initialStatus = {};
            for (const nft of selectedNfts) {
                const collection = nft['collectionAddress'];
                if (!initialStatus[collection]) {
                    if (await collectionApprovedForAll(chainInfos, provider, address, collection)) {
                        initialStatus[collection] = TransactionStatus.SUCCESS;
                    } else {
                        initialStatus[collection] = TransactionStatus.PENDING;
                    }
                }
            }
            setCollectionApprovalStatus(initialStatus);
        }

        loadCollectionApprovalStatus();
    }, [selectedNfts]);

    useEffect(() => {
        const fetchFees = async () => {
            if (!contract || !signer) {
                setFeesCurrencyAmount(0);
                setFeesCurrencyInfos({});
                return;
            }

            try {
                const feesCurrencyAmount = await contract.feesCurrencyAmount();
                const feesCurrencyAddress = await contract.feesCurrencyAddress();

                const currencyContract = new ethers.Contract(feesCurrencyAddress, ERC_20_ABI, signer);
                const currencyInfos = {
                    name: await currencyContract.name(),
                    address: feesCurrencyAddress,
                    decimals: await currencyContract.decimals()
                }

                setFeesCurrencyAmount(feesCurrencyAmount);
                setFeesCurrencyInfos(currencyInfos);
            } catch (error) {
                console.error('Error fetching fee information:', error);
                setFeesCurrencyAmount(0);
                setFeesCurrencyInfos({});
            }
        };

        fetchFees();
    }, [contract, signer]);

    const hideModal = () => {
        setMakeOfferModalShow(false);
    }

    const approveCollection = async (collection) => {
        console.log(`Approving collection '${collection['name']}' at ${collection['address']}`);
        const collectionContract = new ethers.Contract(collection['address'], ERC_721_ABI, signer);
        const tx = await collectionContract.setApprovalForAll(chainInfos.contracts.DealGuardian, true);
        await tx.wait();
    }

    const approveSelectedCollections = async () => {
        for (let collection of uniqueCollectionValues) {
            if (collectionApprovalStatus[collection['address']] === TransactionStatus.SUCCESS) {
                continue;
            }

            setCollectionApprovalStatus(prevStatus => ({
                ...prevStatus,
                [collection['address']]: TransactionStatus.IN_PROGRESS
            }));
            try {
                await approveCollection(collection);
                setCollectionApprovalStatus(prevStatus => ({
                    ...prevStatus,
                    [collection['address']]: TransactionStatus.SUCCESS
                }));
            } catch (error) {
                console.error(`Error approving collection: ${error}`);
                setCollectionApprovalStatus(prevStatus => ({
                    ...prevStatus,
                    [collection['address']]: TransactionStatus.ERROR
                }));
                // Re-throw the error to be caught by the outer try-catch to abort the Approvals and Submission
                throw error;
            }
        }
    }

    const fetchOfferFeesPriceInNativeWei = async () => {
        const fees = await contract.getMakeOfferFeesInWei();
        return fees * 103n / 100n; // add 3% to be sure (price changes, slippage, exchange fees, ...)
    }

    const submitAndNavigateToOffer = async () => {
        console.log(`Submitting offer`);

        try {
            setOfferTransactionStatus(TransactionStatus.IN_PROGRESS);
            let reservedForAddress = buyerAddress;
            if (!buyerAddress || buyerAddress.trim() === '') {
                reservedForAddress = ethers.ZeroAddress;
            }
            const sellAmountWei = ethers.parseUnits(String(sellAmount), sellCurrency.decimals);
            const feesPriceInWei = await fetchOfferFeesPriceInNativeWei();
            const makerAssets = { nfts: selectedNfts, currencies: [] }
            const takerAssets = { nfts: [], currencies: [ { currencyAddress: sellCurrency.address, currencyAmount: sellAmountWei } ] }
            const tx = await contract.makeOffer(makerAssets, takerAssets, reservedForAddress, {value: feesPriceInWei});
            const receipt = await tx.wait();
            provider.once(receipt.hash, (tx) => {
                const event = tx.logs.find(log => log.address.toLowerCase() === chainInfos.contracts.DealGuardian.toLowerCase());
                if (event) {
                    const parsedLog = contract.interface.parseLog(event);
                    console.log(`Parsed log: ${JSON.stringify(parsedLog)}`);
                    let offerId = parsedLog.args.offerId;
                    let makerAddress = parsedLog.args.makerAddress;
                    console.log(`Offer ID: ${offerId}, Seller: ${makerAddress}`);
                    if (makerAddress === address) { // TODO: what happens if there were two offers made in the same block? Does it turn into an array?
                        navigate('/offerMade/' + offerId);
                    }
                }
            });
            setOfferTransactionStatus(TransactionStatus.SUCCESS);
        } catch (e) {
            console.error(`Error submitting offer: ${e}`);
            setOfferTransactionStatus(TransactionStatus.ERROR);
        }
    }

    const handleApproveAndSubmitClick = async () => {
        console.log(`Starting approval(s) and submit offer. Unique Collections: ${JSON.stringify(Object.values(uniqueCollectionValues))}`);

        try {
            await approveSelectedCollections();
            await submitAndNavigateToOffer();
        } catch (e) {
            console.error(`Error while handling approvals/submitting offer: ${e}`);
        }
    };

    let amountFormatted = ethers.formatUnits(feesCurrencyAmount, feesCurrencyInfos.decimals);
    if (amountFormatted.endsWith(".0")) {
        amountFormatted = amountFormatted.replace(".0", "");
    }
    const currencyFormatted = ["USDC", "USD Circle", "USDT", "USD Tether"].includes(feesCurrencyInfos.name) ? "USD" : feesCurrencyInfos.name;
    const feesText = `+ ${amountFormatted} ${currencyFormatted} fee`;

    return (
        <Modal
            show={makeOfferModalShow}
            onHide={hideModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Submit Offer
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <OfferSummary enrichedNfts={selectedNfts} amountFormatted={sellAmount} amountUsdFormatted={sellAmountUsdFormatted} currencyInfo={sellCurrency}  feesText={feesText} feesClassNames="summary-fees-pay" />
                    <div className="mt-4">
                        <p>Please approve the following transactions in your wallet:</p>
                        <ol className="">
                            {uniqueCollectionValues.map(collection => (
                                <li key={collection['address']}>
                                    <span>Approve collection {collection['name']} </span>
                                    <span
                                        className="ms-1">{getTransactionStatusSymbol(collectionApprovalStatus[collection['address']])}</span>
                                </li>
                            ))}
                            <li>
                                <span>Submit the offer </span>
                                <span className="ms-1">{getTransactionStatusSymbol(offerTransactionStatus)}</span>
                            </li>
                        </ol>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={handleApproveAndSubmitClick}
                        className="btn btn-primary btn-lg">Approve and Submit
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default MakeOfferModal;
