import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {getTransactionStatusSymbol} from "../utils/transactionUtils";
import {TransactionStatus} from "../enums";
import {enrichNfts} from "../utils/nftUtils";
import {useBlockchain} from "../contexts/BlockchainContext";
import {useNavigate} from "react-router-dom";
import {ethers} from "ethers";
import {ERC_20_ABI, ERC_721_ABI, FILTER_OFFERS_BY_MAKER} from "../constants";
import OfferSummary from "./OfferSummary";
import {formatStringTwoDecimals} from "../utils/formatUtils";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

const WithdrawOfferModal = ({offerId, offer, withdrawOfferModalShow, setWithdrawOfferModalShow, sellCurrencyAmountFormatted, sellCurrencyAmountUsdFormatted, sellCurrencyInfo}) => {
    const {signer, contract, chainInfos} = useBlockchain();
    const { address } = useWeb3ModalAccount();
    const navigate = useNavigate();

    const [withdrawalTransactionStatus, setWithdrawalTransactionStatus] = useState(TransactionStatus.PENDING);
    const [enhancedNfts, setEnhancedNfts] = useState([]);
    const [unusedApprovalsIfWithdrawThisOffer, setUnusedApprovalsIfWithdrawThisOffer] = useState([]);
    const [feesCurrencyAmount, setFeesCurrencyAmount] = useState(0);
    const [feesCurrencyInfos, setFeesCurrencyInfos] = useState({});

    useEffect(() => {
        const enrichAndSetNfts = async () => {
            setEnhancedNfts(await enrichNfts(chainInfos.chainId, offer.makerAssets.nfts));
        };

        enrichAndSetNfts();
    }, [offer, chainInfos]);

    useEffect(() => {
        const findUnusedApprovalsIfWithdrawThisOffer = async () => {
            if (!enhancedNfts || enhancedNfts.length === 0) {
                setUnusedApprovalsIfWithdrawThisOffer([]);
                return;
            }

            const offersByMaker = await contract.getOffers(address, FILTER_OFFERS_BY_MAKER);

            let collectionAddressesNeededForOpenOffers = new Set();

            for (let offer of offersByMaker) {
                if (offer.offerId === offerId) {
                    continue; // don't count the collections in this offer as we're withdrawing this offer
                }
                for (let nft of offer.makerAssets.nfts) {
                    collectionAddressesNeededForOpenOffers.add(nft.collectionAddress);
                }
            }

            let collectionAddressesInThisOffer = offer.makerAssets.nfts.map(nft => nft.collectionAddress);
            let collectionAddressesToBeRevoked = collectionAddressesInThisOffer.filter(address => !collectionAddressesNeededForOpenOffers.has(address));

            let enrichedCollectionAddressesToBeRevoked = collectionAddressesToBeRevoked.map(address => {
                let enrichedNft = enhancedNfts.find(enrichedNft => enrichedNft.collectionAddress === address);
                return {
                    address: address,
                    name: enrichedNft.collectionName,
                    transactionStatus: TransactionStatus.PENDING
                };
            });

            setUnusedApprovalsIfWithdrawThisOffer(enrichedCollectionAddressesToBeRevoked);
        };

        findUnusedApprovalsIfWithdrawThisOffer();
    }, [enhancedNfts]);

    useEffect(() => {
        const fetchFees = async () => {
            if (!contract || !signer) {
                setFeesCurrencyAmount(0);
                setFeesCurrencyInfos({});
                return;
            }

            try {
                let { currencyAddress, currencyAmount } = await contract.getRefundAmountForWithdrawing(offerId);

                const currencyContract = new ethers.Contract(currencyAddress, ERC_20_ABI, signer);
                const currencyInfos = {
                    name: await currencyContract.name(),
                    address: currencyAddress,
                    decimals: await currencyContract.decimals()
                }

                setFeesCurrencyAmount(currencyAmount);
                setFeesCurrencyInfos(currencyInfos);
            } catch (error) {
                console.error('Error fetching fee information:', error);
            }
        };

        fetchFees();
    }, [contract, signer]);

    const handleWithdrawOfferClick = async () => {
        console.log(`Withdrawing offer: ${offerId}`);
        try {
            setWithdrawalTransactionStatus(TransactionStatus.IN_PROGRESS);

            const tx = await contract.withdrawOffer(offerId);
            await tx.wait();
            console.log(`Withdrew offer ${offerId} successfully`);
            setWithdrawalTransactionStatus(TransactionStatus.SUCCESS);
        } catch (e) {
            console.error(`Error while handling withdrawing offer: ${e}`);
            setWithdrawalTransactionStatus(TransactionStatus.ERROR);
        }
    };

    const handleRemoveApprovalsClick = async () => {
        console.log(`Removing approvals for collections: ${JSON.stringify(unusedApprovalsIfWithdrawThisOffer)}`);

        let updatedApprovals = [...unusedApprovalsIfWithdrawThisOffer]; // Copy of current state

        for (let i = 0; i < updatedApprovals.length; i++) {
            let collection = updatedApprovals[i];

            if (collection['transactionStatus'] === TransactionStatus.SUCCESS) {
                continue;
            }

            try {
                updatedApprovals[i] = { ...collection, transactionStatus: TransactionStatus.IN_PROGRESS };
                setUnusedApprovalsIfWithdrawThisOffer([...updatedApprovals]);

                const collectionContract = new ethers.Contract(collection['address'], ERC_721_ABI, signer);
                const tx = await collectionContract.setApprovalForAll(await contract.getAddress(), false);
                await tx.wait();

                updatedApprovals[i] = { ...collection, transactionStatus: TransactionStatus.SUCCESS };
                setUnusedApprovalsIfWithdrawThisOffer([...updatedApprovals]);
                console.log(`Removed approval for ${collection['address']}`);
            } catch (e) {
                updatedApprovals[i] = { ...collection, transactionStatus: TransactionStatus.ERROR };
                setUnusedApprovalsIfWithdrawThisOffer([...updatedApprovals]);
                console.log(`Error removing approvals:`, e);
            }
        }
        console.log(`Done removing approvals`);
    };

    const hideModal = () => {
        // once the offer has been withdrawn you can't close the modal anymore
        if (withdrawalTransactionStatus !== TransactionStatus.SUCCESS) {
            setWithdrawOfferModalShow(false);
        }
    }

    const navigateToMyOffers = () => {
        navigate('/myOffers');
    }

    const revokalsAvailable = () => {
        return unusedApprovalsIfWithdrawThisOffer && unusedApprovalsIfWithdrawThisOffer.some(collection => collection.transactionStatus !== TransactionStatus.SUCCESS);
    }

    let feesText;

    if (feesCurrencyAmount > 0) {
        let amountFormatted = formatStringTwoDecimals(ethers.formatUnits(feesCurrencyAmount, feesCurrencyInfos.decimals));
        const currencyFormatted = ["USDC", "USD Circle", "USDT", "USD Tether"].includes(feesCurrencyInfos.name) ? "USD" : feesCurrencyInfos.name;
        feesText = `+ ${amountFormatted} ${currencyFormatted} refund`;
    }

    return (
        <Modal
            show={withdrawOfferModalShow}
            onHide={hideModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton={withdrawalTransactionStatus !== TransactionStatus.SUCCESS}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Withdraw Offer
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <OfferSummary enrichedNfts={enhancedNfts} amountFormatted={sellCurrencyAmountFormatted} amountUsdFormatted={sellCurrencyAmountUsdFormatted} currencyInfo={sellCurrencyInfo} feesText={feesText} feesClassNames="summary-fees-refund" />
                    <div className="container mt-4">
                        <p>Please approve the following transactions in your wallet:</p>
                        <ol className="">
                            <li key="offer">
                                <span>Withdraw the offer </span>
                                <span className="me-2">{getTransactionStatusSymbol(withdrawalTransactionStatus)}</span>
                            </li>
                            {/* TODO: show info tooltip when/why you should skip this */}
                            {(unusedApprovalsIfWithdrawThisOffer && unusedApprovalsIfWithdrawThisOffer.length > 0) ? unusedApprovalsIfWithdrawThisOffer.map(collection => (
                                <li key={collection['address']}>
                                    <span>Optional: Revoke approval for collection {collection['name']} </span>
                                    <span
                                        className="me-2">{getTransactionStatusSymbol(collection['transactionStatus'])}</span>
                                </li>
                            )) : (<p className="small-hint">(No NFT collections require revoking approval)</p>)}
                        </ol>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {withdrawalTransactionStatus !== TransactionStatus.SUCCESS && (
                    <button onClick={handleWithdrawOfferClick}
                            className="btn btn-warning btn-lg">Withdraw offer
                    </button>
                )}
                {withdrawalTransactionStatus === TransactionStatus.SUCCESS && (
                    <div>
                        <button onClick={navigateToMyOffers}
                                className={`btn btn-lg me-2 ${revokalsAvailable() ? "btn-secondary" : "btn-primary"}`}>Done
                        </button>
                        {revokalsAvailable() && (<button onClick={handleRemoveApprovalsClick}
                                className="btn btn-primary btn-lg">Revoke all approvals
                        </button>)}
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default WithdrawOfferModal;
