import React from 'react';
import {generateDynamicUrl} from "../utils/chainUtils";

const NftExternalLinks = ({chainInfos, contractAddress, tokenId}) => {
    const explorerUrl = generateDynamicUrl(chainInfos.explorerUrlTemplate, { contractAddress, tokenId });
    const marketUrl = generateDynamicUrl(chainInfos.marketUrlTemplate, { contractAddress, tokenId });

    return (
        <div className="d-flex">
            <a
                href={explorerUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-external-link">
                <img src={chainInfos.explorerIcon} className="card-img-top img-fluid"
                     alt="Logo for Blockchain Explorer"
                     style={{objectFit: 'cover', height: '20px'}}/>
            </a>
            <a
                href={marketUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-external-link ms-2">
                <img src={chainInfos.marketIcon} className="card-img-top img-fluid"
                     alt="Logo for Marketplace"
                     style={{objectFit: 'cover', height: '20px'}}/>
            </a>
        </div>
    );
};

export default NftExternalLinks;
