import React, {useState} from 'react';
import CryptoCurrencyDropdown from "./CryptoCurrencyDropdown";
import {isValidAddress} from "../utils/regexUtils";
import {ethers} from "ethers";
import {ERC_20_ABI} from "../constants";
import {useBlockchain} from "../contexts/BlockchainContext";
import {fetchHoneypotIsData} from "../utils/securityUtils";
import {formatStringTwoDecimals} from "../utils/formatUtils";

const CryptoAmountField = ({amount, amountUsdFormatted, handleAmountChange, selectedCurrency, handleSelectedCurrencyChange, currencyCustomTokenAddress, handleCurrencyCustomTokenAddressChange, onValidationChange}) => {
    const {signer, chainInfos} = useBlockchain();

    const [errorMessage, setErrorMessage] = useState("");
    const [warningMessage, setWarningMessage] = useState("");

    const handleAmountChangeInternal = (event) => {
        handleAmountChange(event.target.value);
        validate(event.target.value, selectedCurrency, currencyCustomTokenAddress);
    };

    const handleCurrencyCustomTokenAddressChangeInternal = (event) => {
        handleCurrencyCustomTokenAddressChange(event.target.value);
        validate(amount, selectedCurrency, event.target.value);
    };

    const handleSelectedCurrencyChangeInternal = (value) => {
        handleSelectedCurrencyChange(value);
        validate(amount, value, currencyCustomTokenAddress);
    };

    const validate = async (amount, selectedCurrency, tokenAddress) => {
        if (!amount || parseFloat(amount) === 0) {
            setErrorMessage("No amount set");
            onValidationChange(false);
        } else if (parseFloat(amount) <= 0) {
            setErrorMessage("Value must be positive");
            onValidationChange(false);
        } else if (selectedCurrency.isCustom && !isValidAddress(tokenAddress)) {
            setErrorMessage("Invalid token address");
            onValidationChange(false);
        } else if (selectedCurrency.isCustom && isValidAddress(tokenAddress) && !chainInfos.abilities.honeypot_is) {
            setErrorMessage("");
            setWarningMessage(`Can't load security data for token on ${chainInfos.name}. Please only continue if you know and trust the token and are aware of potential token taxes.`);
            onValidationChange(true);
        } else if (selectedCurrency.isCustom && isValidAddress(tokenAddress)) {
            try {
                const currencyContract = new ethers.Contract(tokenAddress, ERC_20_ABI, signer);
                await currencyContract.name();
                await currencyContract.decimals();

                setErrorMessage("");
                onValidationChange(true);

                const honeypotData = await fetchHoneypotIsData(tokenAddress, chainInfos);

                if (honeypotData && honeypotData['simulationResult']) {
                    const tax = honeypotData['simulationResult']['transferTax'];
                    if (tax > 0) {
                        setWarningMessage(`It looks like the custom token has a transfer tax of ${formatStringTwoDecimals(String(tax))}%, so you'll receive less than you specified. The buyer sends ${formatStringTwoDecimals(String(amount))} and you receive around ${formatStringTwoDecimals(String(parseFloat(amount) - (tax * parseFloat(amount) / 100)))}`);
                    }
                } else {
                    setWarningMessage("Couldn't load security data on the supplied token");
                }
            } catch (e) {
                console.log(e);
                setErrorMessage("No ERC-20 token at address");
                onValidationChange(false);
            }
        } else {
            setErrorMessage("");
            onValidationChange(true);
        }
    }

    if (!selectedCurrency) {
        return <p>Loading...</p>
    }

    return (
        <div className="col-md-6">
            <div className="input-group">
                <input
                    type="number"
                    className="form-control"
                    placeholder="Enter amount"
                    step="0.05"
                    value={amount}
                    min="0"
                    onChange={(event) => handleAmountChangeInternal(event)}
                />
                <CryptoCurrencyDropdown selectedCurrency={selectedCurrency}
                                        handleSelectedCurrencyChange={handleSelectedCurrencyChangeInternal}/>
            </div>
            {selectedCurrency.isCustom && <input
                type="text"
                className="form-control"
                placeholder="Token Adress"
                value={currencyCustomTokenAddress}
                onChange={(event) => handleCurrencyCustomTokenAddressChangeInternal(event)}
            />}
            <p className="conversion-rate-hint">≈ {amountUsdFormatted} USD</p>
            <span className="validation-error-message">{errorMessage}</span>
            <span style={{color: "orange"}}>{warningMessage}</span>
        </div>
    );
};

export default CryptoAmountField;
