import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {ethers} from "ethers";
import {getCurrencyByAddress, getCurrencyByName} from "../utils/currencyUtils";
import {ERC_20_ABI} from "../constants";
import {useBlockchain} from "../contexts/BlockchainContext";
import {formatStringTwoDecimals, getAddressDisplayName} from "../utils/formatUtils";
import {enrichNfts} from "../utils/nftUtils";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {getConversionFormatted} from "../utils/conversionUtils";

const OfferTable = ({offers, showMaker, showReservedFor}) => {
    const {provider, chainInfos} = useBlockchain();

    const [currencyInfos, setCurrencyInfos] = useState({});
    const [usdConversions, setUsdConversions] = useState({});
    const [enhancedNfts, setEnhancedNfts] = useState({});

    useEffect(() => {
        const enrichAndSetNfts = async () => {
            if (!chainInfos || !offers) {
                setEnhancedNfts({});
            }

            let enhancedNftsNew = {};
            for (let offerId of Object.keys(offers)) {
                const offer = offers[offerId];
                enhancedNftsNew[`m_${offerId}`] = await enrichNfts(chainInfos.chainId, offer.makerAssets.nfts);
                enhancedNftsNew[`t_${offerId}`] = await enrichNfts(chainInfos.chainId, offer.takerAssets.nfts);
            }
            setEnhancedNfts(enhancedNftsNew);
        };

        enrichAndSetNfts();
    }, [chainInfos, offers]);

    useEffect(() => {
        const loadCurrencyInfo = async (address) => {
            let sellCurrencyInfo = getCurrencyByAddress(chainInfos, address);
            if (sellCurrencyInfo) {
                return sellCurrencyInfo;
            } else {
                const currencyContract = new ethers.Contract(address, ERC_20_ABI, provider);
                return {
                    name: await currencyContract.name(),
                    decimals: await currencyContract.decimals(),
                    image: "/icon/gold-coin.svg",
                    isCustom: true,
                    address: address
                };
            }
        }

        const loadCurrencyInfos = async () => {
            if (!provider || !offers) {
                setCurrencyInfos({});
                return;
            }

            let currencyInfosNew = {};

            for (let offerId of Object.keys(offers)) {
                const offer = offers[offerId];
                for (let currencyAsset of offer.makerAssets.currencies) {
                    let address = currencyAsset.currencyAddress;
                    currencyInfosNew[address] = await loadCurrencyInfo(address);
                }
                for (let currencyAsset of offer.takerAssets.currencies) {
                    let address = currencyAsset.currencyAddress;
                    currencyInfosNew[address] = await loadCurrencyInfo(address);
                }
            }
            setCurrencyInfos(currencyInfosNew);
        }

        loadCurrencyInfos();
    }, [provider, offers]);

    useEffect(() => {
        const loadUsdConversions = async () => {
            if (!provider || !offers) {
                setUsdConversions({});
                return;
            }

            let usdConversions = {};

            for (let offerId of Object.keys(offers)) {
                for (let currencyAsset of offers[offerId].makerAssets.currencies) {
                    usdConversions[`${currencyAsset.currencyAddress}_${currencyAsset.currencyAmount}`] = await getConversionFormatted(chainInfos, provider, [currencyAsset.currencyAddress, getCurrencyByName(chainInfos, "USDC").address], currencyAsset.currencyAmount);
                }
                for (let currencyAsset of offers[offerId].takerAssets.currencies) {
                    usdConversions[`${currencyAsset.currencyAddress}_${currencyAsset.currencyAmount}`] = await getConversionFormatted(chainInfos, provider, [currencyAsset.currencyAddress, getCurrencyByName(chainInfos, "USDC").address], currencyAsset.currencyAmount);
                }
            }
            setUsdConversions(usdConversions);
        }

        loadUsdConversions();
    }, [provider, offers]);

    if (!offers || !currencyInfos || (Object.keys(offers).length !== 0 && Object.keys(currencyInfos).length === 0)) {
        return <p>Loading...</p>
    }

    return (
        <div>
            <table className="table table-bordered">
                <thead className="thead-dark">
                <tr>
                    <th scope="col">Offer ID</th>
                    <th scope="col">Assets Offered</th>
                    <th scope="col">Assets Requested</th>
                    {showMaker && <th scope="col">Offerer</th>}
                    {showReservedFor && <th scope="col">Reserved For</th>}
                </tr>
                </thead>
                <tbody>
                {Object.entries(offers).map(([id, offer], index) => (
                    <tr key={index}>
                        <td><Link to={`/offer/${id}`}>{id}</Link></td>
                        <td>
                            {offer.makerAssets.currencies && offer.makerAssets.currencies.map((currencyAsset) => (
                                <div key={currencyAsset.currencyAddress}>
                                    <p>{formatStringTwoDecimals(ethers.formatUnits(currencyAsset.currencyAmount, currencyInfos[currencyAsset.currencyAddress].decimals))} {currencyInfos[currencyAsset.currencyAddress].name}</p>
                                    <span className="conversion-rate-hint">≈ {usdConversions[`${currencyAsset.currencyAddress}_${currencyAsset.currencyAmount}`]} USD</span>
                                    <br />
                                </div>
                            ))}
                            {enhancedNfts && enhancedNfts[`m_${id}`] && enhancedNfts[`m_${id}`].map((nft) => (
                                <OverlayTrigger
                                    key={`${nft.collectionAddress}-${nft.tokenId}`}
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-${nft.collectionAddress}-${nft.tokenId}`}>
                                            <div className="nft-tooltip">
                                                <img src={nft.thumbnail} alt={nft.name} className="large-nft-image"/>
                                                <div>{nft.collectionName}</div>
                                                <div>Token ID: {nft.tokenId}</div>
                                            </div>
                                        </Tooltip>
                                    }>
                                    <img src={nft.thumbnail} alt={nft.name} className="nft-thumbnail mx-1"/>
                                </OverlayTrigger>
                            ))}
                        </td>
                        <td>
                            {offer.takerAssets.currencies && offer.takerAssets.currencies.map((currencyAsset) => (
                                <div key={currencyAsset.currencyAddress}>
                                    <p className="m-0">{formatStringTwoDecimals(ethers.formatUnits(currencyAsset.currencyAmount, currencyInfos[currencyAsset.currencyAddress].decimals))} {currencyInfos[currencyAsset.currencyAddress].name}</p>
                                    <span className="conversion-rate-hint">≈ {usdConversions[`${currencyAsset.currencyAddress}_${currencyAsset.currencyAmount}`]} USD</span>
                                    <br />
                                </div>
                            ))}
                            {enhancedNfts && enhancedNfts[`t_${id}`] && enhancedNfts[`t_${id}`].map((nft) => (
                                <OverlayTrigger
                                    key={`${nft.collectionAddress}-${nft.tokenId}`}
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-${nft.collectionAddress}-${nft.tokenId}`}>
                                            <div className="nft-tooltip">
                                                <img src={nft.thumbnail} alt={nft.name} className="large-nft-image"/>
                                                <div>{nft.collectionName}</div>
                                                <div>Token ID: {nft.tokenId}</div>
                                            </div>
                                        </Tooltip>
                                    }>
                                    <img src={nft.thumbnail} alt={nft.name} className="nft-thumbnail mx-1"/>
                                </OverlayTrigger>
                            ))}
                        </td>
                        {showMaker && <td>{getAddressDisplayName(offer.makerAddress)}</td>}
                        {showReservedFor && <td>{offer.reservedForAddress === ethers.ZeroAddress ? "-" : getAddressDisplayName(offer.reservedForAddress)}</td>}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default OfferTable;
