import React, {useEffect, useRef, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import {ethers} from "ethers";
import {useBlockchain} from "contexts/BlockchainContext";
import ConnectedWalletRequired from "components/ConnectedWalletRequired";
import CryptoAmountField from "./CryptoAmountField";
import NftCards from "./NftCards";
import {getCurrencyByName} from "../utils/currencyUtils";
import {getConversionFormatted, RATE_UNKNOWN} from "../utils/conversionUtils";
import MakeOfferModal from "./MakeOfferModal";
import {CUSTOM_TOKEN, ERC_20_ABI} from "../constants";
import {isValidAddress} from "../utils/regexUtils";
import {findThumbnail, getNftsForOwner} from "../utils/nftUtils";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

function MakeOffer() {
    const {provider, signer, chainInfos} = useBlockchain();
    const { address } = useWeb3ModalAccount();
    const tooltipRef = useRef(null);
    const [nfts, setNfts] = useState([]);
    const [enrichedNfts, setEnrichedNfts] = useState([]);
    const [selectedNfts, setSelectedNfts] = useState([]);
    const [sellAmount, setSellAmount] = useState(0);
    const [sellAmountUsdFormatted, setSellAmountUsdFormatted] = useState(RATE_UNKNOWN);
    const [sellCurrency, setSellCurrency] = useState({});
    const [sellCurrencyCustomTokenAddress, setSellCurrencyCustomTokenAddress] = useState("");
    const [sellCryptoValid, setSellCryptoValid] = useState(false);
    const [buyerAddress, setBuyerAddress] = useState("");
    const [buyerAddressErrorMessage, setBuyerAddressErrorMessage] = useState("");
    const [transactionModalShow, setTransactionModalShow] = useState(false);
    const [uniqueCollections, setUniqueCollections] = useState({});

    useEffect(() => {
        const fetchNFTs = async () => {
            if (!address || !chainInfos) {
                setNfts([]);
                setEnrichedNfts([]);
                return;
            }

            try {
                let nftsForOwner = await getNftsForOwner(chainInfos.chainId, address);
                let nfts = nftsForOwner['ownedNfts'];
                let enrichedNfts = [];

                nfts = nfts.filter(nft => nft.tokenType === "ERC721" || nft.tokenType === "UNKNOWN");

                for (let nft of nfts) {
                    const enrichedNft = {
                        collectionAddress: nft['contract']['address'],
                        tokenId: nft['tokenId'],
                        name: nft['name'],
                        thumbnail: findThumbnail(nft),
                    };

                    if (!nft['media'] || !nft['media'][0]) {
                        nft['thumbnail'] = "/genericNft.jpg";
                        nft['thumbnailVideo'] = false;
                        enrichedNft['thumbnailVideo'] = false;
                        enrichedNfts.push(enrichedNft);
                        continue;
                    }
                    const media = nft['media'][0];

                    nft['thumbnail'] = media['thumbnail'];
                    nft['thumbnailVideo'] = media['format'] === "mp4";
                    enrichedNft['thumbnailVideo'] = nft['thumbnailVideo'];
                    if (nft['thumbnailVideo']) {
                        nft['thumbnail'] = media['raw'];
                        enrichedNft['thumbnail'] = media['raw'];
                    }
                    enrichedNfts.push(enrichedNft);
                }

                // console.log(`Nfts: ${JSON.stringify(nfts)}`);
                // console.log(`EnrichedNfts: ${JSON.stringify(enrichedNfts)}`);
                setNfts(nfts);
                setEnrichedNfts(enrichedNfts);
            } catch (error) {
                console.error("Error fetching NFTs:", error);
                setNfts([]);
                setEnrichedNfts([]);
            }
        };

        fetchNFTs();
    }, [address, chainInfos]);

    const lookupCollectionName = (collectionAddress) => {
        for (let nft of nfts) {
            if (nft['contract']['address'] === collectionAddress) {
                return nft['contract']['name'];
            }
        }
        return "N/A";
    }

    useEffect(() => {
        const uniqueCollections = selectedNfts.reduce((acc, nft) => {
            const address = nft['collectionAddress'];
            if (!acc[address]) {
                acc[address] = {
                    address: address,
                    name: lookupCollectionName(nft['collectionAddress'])
                };
            }
            return acc;
        }, {});
        setUniqueCollections(uniqueCollections);
    }, [selectedNfts]);

    useEffect(() => {
        const loadNewConversionRate = async () => {
            if (!provider || !sellAmount || !sellCurrency || !chainInfos) {
                setSellAmountUsdFormatted(RATE_UNKNOWN);
                return;
            }

            try {
                const newValueWei = ethers.parseUnits(String(sellAmount), sellCurrency.decimals);
                let usdFormatted = await getConversionFormatted(chainInfos, provider, [sellCurrency.address, getCurrencyByName(chainInfos, "USDC").address], newValueWei);
                setSellAmountUsdFormatted(usdFormatted);
            } catch (error) {
                console.error("Error fetching conversion rate:", error);
                setSellAmountUsdFormatted(RATE_UNKNOWN);
            }
        };

        loadNewConversionRate();
    }, [provider, sellAmount, sellCurrency, chainInfos]);

    useEffect(() => {
        setSellCurrency(getCurrencyByName(chainInfos, "wETH"));
    }, [chainInfos]);

    const handleSelectionClick = (nft) => {
        setSelectedNfts((prevSelectedNfts) => {
            if (prevSelectedNfts.some(selectedNft => selectedNft.collectionAddress === nft.collectionAddress && selectedNft.tokenId === nft.tokenId)) {
                return prevSelectedNfts.filter(item => item.collectionAddress !== nft.collectionAddress || item.tokenId !== nft.tokenId);
            } else {
                return [...prevSelectedNfts, nft];
            }
        });
    };

    const handleSellAmountChange = (value) => {
        setSellAmount(value);
    };

    const handleSellCurrencyChange = (value) => {
        setSellCurrency(value);
    };

    const updateCustomCurrencyInfos = async (tokenAddress) => {
        if (sellCurrency.name === CUSTOM_TOKEN.name && sellCurrency.decimals === CUSTOM_TOKEN.decimals && sellCurrency.image === CUSTOM_TOKEN.image
            && isValidAddress(tokenAddress)) {
            try {
                const currencyContract = new ethers.Contract(tokenAddress, ERC_20_ABI, signer);
                setSellCurrency({
                    name: await currencyContract.name(),
                    decimals: await currencyContract.decimals(),
                    image: sellCurrency.image,
                    isCustom: true,
                    address: tokenAddress
                });
            } catch (e) {
                console.error("Error fetching custom currency infos:", e);
            }
        }
    }

    const handleSellCurrencyCustomTokenAddressChange = (value) => {
        setSellCurrencyCustomTokenAddress(value);

        updateCustomCurrencyInfos(value);
    };

    const handleBuyerAddressChange = (event) => {
        setBuyerAddress(event.target.value);
        let errorMessage = "";
        if (event.target.value && !isValidAddress(event.target.value)) {
            errorMessage = "Invalid address";
        }
        setBuyerAddressErrorMessage(errorMessage);
    };

    const handleSellCryptoValidationChange = (isValid) => {
        setSellCryptoValid(isValid);
    };

    const handleProceedClick = () => {
        setTransactionModalShow(true);
    };

    const isProceedButtonDisabled = () => {
        if (!sellCryptoValid) {
            return true;
        }
        if (selectedNfts.length === 0) {
            return true;
        }
        if (buyerAddressErrorMessage) {
            return true;
        }
        return false;
    }

    const uniqueCollectionValues = Object.values(uniqueCollections);

    const renderTooltip = (props) => (
        <Tooltip className="text-start" id="selected-nft-statusbar-tooltip" {...props}>
            {selectedNfts && selectedNfts.length > 0 ? (
                <ul>
                    {selectedNfts.map(nft => (
                        <li key={nft['collectionAddress'] + nft['tokenId']}>
                            <span>{nft['collectionName']} #{nft['tokenId']}</span>
                        </li>
                    ))}
                </ul>
            ) : (
                "No NFTs selected"
            )}
        </Tooltip>
    );

    if (!address) {
        return <ConnectedWalletRequired/>;
    }

    if (sellCurrency === null) {
        return <p>Loading...</p>;
    }

    return (
        <div className="container mt-4"
             style={{paddingBottom: '100px'}}> {/* Add padding at bottom to account for the fixed bottom section */}
            <h1 className="text-center mb-4">Sell your NFTs</h1>

            <div>
                {nfts && nfts.length > 0 ? (
                    <div>
                        <h2>You give</h2>
                        <NftCards selectedNfts={selectedNfts} handleSelectionClick={handleSelectionClick} nftsRaw={nfts.map(nft => {
                            return {
                                collectionAddress: nft.contract.address,
                                tokenId: nft.tokenId
                            }})} enrichedNftsParent={enrichedNfts} />
                        <br/>
                        <h2>You get</h2>
                        <div className="container mt-4">
                            <CryptoAmountField amount={sellAmount} amountUsdFormatted={sellAmountUsdFormatted} handleAmountChange={handleSellAmountChange}
                                               selectedCurrency={sellCurrency} handleSelectedCurrencyChange={handleSellCurrencyChange}
                                               currencyCustomTokenAddress={sellCurrencyCustomTokenAddress} handleCurrencyCustomTokenAddressChange={handleSellCurrencyCustomTokenAddressChange}
                                               onValidationChange={handleSellCryptoValidationChange}/>
                        </div>
                        <br/>
                        <h2>Buyer Restrictions (Optional)</h2>
                        <div className="container mt-4">
                            <p>If you've already made an OTC deal with someone you can restrict who the buyer can be.</p>
                            <div className="col-md-6">
                                <input className="form-control"
                                       placeholder="0x..."
                                       value={buyerAddress}
                                       onChange={handleBuyerAddressChange}/>
                                <span className="validation-error-message">{buyerAddressErrorMessage}</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p className="text-center mb-4">You don't have any NFTs yet in this wallet on the selected
                        chain...</p>
                )}
            </div>

            <div className="d-flex justify-content-between align-items-center fixed-bottom p-3 bg-white border-top">
                <div>
                    <p>You are selling <strong>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}>
                            <span ref={tooltipRef} data-bs-toggle="tooltip">
                                <span className={selectedNfts.length === 0 ? "summary-invalid" : ""}>{selectedNfts.length} NFT{selectedNfts.length === 1 ? "" : "s"}</span>
                            </span>
                        </OverlayTrigger> for <span className={!sellCryptoValid ? "summary-invalid" : ""}>{sellAmount} {sellCurrency.name}</span></strong> to {buyerAddress ?
                        <span className={buyerAddressErrorMessage ? "summary-invalid" : ""}>{buyerAddress}</span> : "anyone"}
                    </p>
                </div>
                <div>
                    <div>
                        <button onClick={handleProceedClick} disabled={isProceedButtonDisabled()} className="btn btn-primary btn-lg">Proceed...</button>
                        <MakeOfferModal
                            makeOfferModalShow={transactionModalShow}
                            setMakeOfferModalShow={setTransactionModalShow}
                            selectedNfts={selectedNfts}
                            uniqueCollectionValues={uniqueCollectionValues}
                            sellAmount={sellAmount}
                            sellAmountUsdFormatted={sellAmountUsdFormatted}
                            sellCurrency={sellCurrency}
                            buyerAddress={buyerAddress}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MakeOffer;
