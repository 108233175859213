import React, {useEffect, useState} from 'react';
import {useBlockchain} from "../contexts/BlockchainContext";
import OfferTable from "./OfferTable";

function BrowseOffers() {
    const {contract, provider} = useBlockchain();

    const [offers, setOffers] = useState([]);

    useEffect(() => {
        const fetchOffers = async () => {
            if (!contract || !provider) {
                setOffers([]);
                return;
            }

            try {
                const offerIds = await contract.getActiveOfferIds();

                const offers = {};
                for (let offerId of offerIds) {
                    let {
                        makerAddress,
                        makerAssets,
                        takerAssets,
                        reservedForAddress,
                        offerMadeTimestamp
                    } = await contract.getOffer(offerId);
                    let offer = {
                        makerAddress: makerAddress,
                        makerAssets: makerAssets,
                        takerAssets: takerAssets,
                        reservedForAddress: reservedForAddress,
                        offerMadeTimestamp: offerMadeTimestamp
                    };
                    offers[offerId] = offer;
                }
                setOffers(offers);
            } catch (error) {
                console.error('Error fetching offers:', error);
                setOffers([]);
            }
        };

        fetchOffers();
    }, [contract, provider]);

    return (
        <div className="container mt-4">
            <h1 className="text-center mb-4">Browse Offers</h1>
            <div className="d-flex justify-content-center">
                <p className="text-center mb-3">All open offers:</p>
            </div>
            <div>
                <OfferTable offers={offers} showMaker={true} showReservedFor={true} />
            </div>
        </div>
    );
}

export default BrowseOffers;
